export const italianStrings = {

    /********************* MAIN SCREEN ******************************/

    add_new_event: ' Nuovo Evento',

    /********************* EDIT MODAL ******************************/
    edit_event:'Modifica Evento',

    information: 'Generali',
    main_menu: 'Menu principale',
    appearance:'Aspetto',
    custom_labels: 'Etichette personalizzate',
    questions:'Domande',
    tweets:'Tweets',
    agenda:'Agenda',

    event_title:'Titolo Evento',
    event_code:'Codice Evento',
    starting_date:'Data Inizio',
    ending_date:'Data Fine',

    update_qr_code:'Aggiorna Qr Code',
    download_qr_code:'Scarica Qr Code',
    cancel:'Annulla',
    update:'Aggiorna',
    ok:'Ok',
    create:'Crea',
    yes:'Sì',
    no:"No",

    choose_language:"Seleziona Lingua",
    header_background_color:"Colore Sfondo Intestazione",
    header_text_color:"Colore Testo Intestazione",
    background_color: 'Colore Sfondo',
    text_color: 'Colore Testo',
    background_color_theme: "Colore Sfondo (tema)",
    text_color_theme: 'Colore Testo (tema)',
    correct_question_color: 'Correggi Colore Domanda (Barra Avanzamento)',
    home_page: 'Home Page *',
    intro_page: 'Intro Page *',
    logo: 'Logo *',
    upload_note: "* Utilizzare il trascinamento della selezione o fare clic nella casella per scegliere e caricare l'immagine.",
    show_vote_counter: 'Mostra contatore voti:',
    order_question_ptions: "Opzioni ordine domande:",
    number_live_screens: 'Numero di schermi live:',
    display_vote_results: "Visualizza Risultati Voto in:",
    disable: "Disattivare",
    active:"Attivare",
    one: 'Uno',
    two: 'Due',
    percentage: 'Percentuale',
    numbers: 'Numeri',

    show_event_info:"Mostra informazioni Evento",

    show_tweets_participants: 'Mostra Tweet ai Partecipanti',
    moderation: "Moderazione",
    define_tweets_show_participants: 'Definisci i tweet da mostrare ai partecipanti:',
    with_moderator: 'Con Moderatore',
    without_moderator: 'Senza Moderatore',
    live: 'Live',
    approved: 'Approvato',

    available_languages: "Lingue disponibili",

    english:"Inglese",
    italian:"Italiano",

    define_event_title: 'Si prega di definire un titolo per questo evento.',
    event_code_exists: "Questo codice evento esiste già, sceglierne un altro!",


    survey_link_address:"Indirizzo Link Sondaggio: ",
    agenda_link_address: "Indirizzo Link Agenda: ",

    qr_text: "Testo lettore QR client",

    theme_big_screen:"Tema (Grande Schermo)",
    theme_participant:"Tema (App Partecipante)",
    participant_profile: "Profilo del partecipante",
    active_email: "Attiva email nel profilo dei partecipanti",
    active_profile: "Attiva profilo",

    show_header_bar: "Mostra barra di intestazione",

    automatic_jump_poll: 'Passaggio automatico al Sondaggio:',    

/********************* HEADER TAB ******************************/
    all_events: 'TUTTI GLI EVENTI',
    event_settings: "IMPOSTAZIONI EVENTO",
    current_event:"EVENTO CORRENTE",
    active_participants: "PARTECIPANTI",
    send_message: 'INVIA MESSAGGIO',
    hTweets: 'TWEETS',
    hQuestions: 'DOMANDE',
    hSurvey: 'SONDAGGIO',
    your_events:"I tuoi eventi",
    shared_events:"Eventi condivisi",

/*********************** MESSAGE MODAL *********************/
    send_message_participants: "Invia messaggio ai partecipanti",
    message: "Messaggio",
    title: "Titolo",
    importance: "Rilevanza",
    high_priority: "Alta priorità",
    normal: "normale",
    note_message: "* Si prega di definire il messaggio da inviare.",
    note_importance: "* Si prega di definire la rilevanza del tuo messaggio.",
    sendBtn: "Invia",

/*********************** SINGLE EVENT PAGE *********************/
  new_item:"Nuovo elemento",
  eventItems:"Elementi Evento",
  roomItems: "Elementi Sala",
    external_content: 'Contenuto esterno',
    survey: "Sondaggio",
  question_answer: "Domanda & Risposta",
  folder:"Cartella",
  room:"Sala",
  library:"Biblio",
  eventInfo:"Informazioni sull'evento",
  qrcode:"Lettore QR",
  survey_mob:"Sondaggio",
  question_answer_mob: "Domanda",
  folder_mob:"Cartella",
  room_mob:"Sala",
  library_mob:"Biblio",
  eventInfo_mob:"Info",
  qrcode_mob:"QR",
  description_library:"Questo strumento consente ai partecipanti la possibilità di visualizzare i documenti relativi al materiale bibliografico dell'evento",
  description_library1: "Strumento per creare bibliografie per depositare i documenti",
  description_library2: "visualizzare i documenti relativi al materiale bibliografico dell'evento",
  description_qrcode:"Questo strumento permette ai partecipandi di aprire i documenti del materiale bibliografico tramite codice QR",
  description_qrcode1: "Strumento per generare un lettore QR per leggere i documenti",
  description_qrcode2: "aprire i file del materiale bibliografico tramite codice QR",
  description_eventInfo:"to be defined ....",
  description_eventInfo1: "to be defined ",
  description_eventInfo2: "to be defined",
  description_agenda: "Questo strumento permette di archiviare globalmente file o URL relativi all'agenda dell'evento",
  description_agenda1: "Strumento per raccogliere file/URL d'agenda",
  description_agenda2: "store globally agenda files/URLs of the event",
  description_room:"Crea un contenitore specifico in cui inserire altri elementi (ad esempio survey, tweet, domande) per organizzare le attività dell'evento in base alla sala in cui avranno luogo",
  description_room1: "Contenitore specifico per definire le sale dell'evento",
  description_room2: "inserire altri elementi (ad esempio survey, tweet, domande) per organizzare le attività dell'evento in base alla sala in cui avranno luogo",
    description_external_content: "Questo strumento permette ai partecipanti di aprire un contenuto esterno",

    // DA CANCELLARE
  description_survey:"Questo strumento permette ai partecipanti di partecipare ad un sondaggio",
  description_survey1:"Strumento per reindirizzare gli utenti ad un sondaggio",
  description_survey2:"partcipare ad un sondaggio",

  description_question:"Questo strumento offre ai partecipanti la possibilità di inviare domande a scelta multipla o di testo aperto e mostra in tempo reale i risultati della risposta alla schermata principale.",
  description_question1:"Strumento per la creazione di sessioni di domande",
  description_question2:"inviare domande a scelta multipla o a testo aperto e mostra in tempo reale i risultati della risposta alla schermata principale.",
  description_tweet:"Questo strumento consente ai partecipanti di inviare domande o scrivere commenti al panel dei relatori. I messaggi dei partecipanti possono essere moderati prima di essere inviati ai relatori.",
  description_tweet1:"Strumento per la moderazione e l'invio di tweet",
  description_tweet2:"inviare domande o scrivere commenti al pannello dei relatori. I messaggi dei partecipanti possono essere moderati prima di essere inviati ai relatori.",
  description_folder:"Crea un contenitore generico in cui inserire altri elementi per organizzare meglio le attività nel tuo evento (ad esempio ‘Prima sessione’, ‘Verifica dell'apprendimento’, ‘Workshop pomeridiano’). Questa cartella può contenere elementi o ulteriori cartelle.",
  description_folder1:"Contenitore generico per meglio organizzare l'evento",
  description_folder2:"inserire altri elementi per organizzare meglio le attività nel tuo evento (ad esempio ‘Prima sessione’, ‘Verifica dell'apprendimento’, ‘Workshop pomeridiano’). Questa cartella può contenere elementi o ulteriori cartelle.",

  edit_room_item:"Modifica Tema della Sala",
  room_title:"Titolo della sala",
  select_room_icon_color:"Seleziona colore icona",
/*********************** QUESTIONS PAGE *********************/
    new_question: " Nuova domanda",
    list_questions:"LISTA DI DOMANDE",
    active_question:"DOMANDA ATTIVA",
    archived_sessions:"SESSIONI ARCHIVATE",
    live_session:"SESSIONE LIVE",

    delete_question_session_title:"Elimina la sessione della domanda",
    delete_question_session_text:"Sei sicuro di voler eliminare questa sessione?",
    
    /****************************** LIVE SCREEN ***************************/
    join_event_through: "Partecipa a questo evento",
    using_event_code: "Utilizzando il codice",
    average_votes: "Media Voti",

    /************************* Library Screen ***************************/
    title_uploaded_files: "Elenco dei file caricati",
    file_name: "Nome file",
    drag_file_note_library1: "Fai clic per selezionare il file ",
    drag_file_note_library2: "oppure usa Drag & Drop",
    edit_file_name: "Modifica nome",
    remove_file: "Rimuovi file",
    upload_file: "Carica file",
    drag_and_drop: "Seleziona i file o usa il Drag & Drop",
    file_url: "URL File Esterno",
    /************************** Agenda Screen ************************/
    select_language: " Seleziona la lingua",
    upload_agenda_file: "Carica File:",

    /**************************** Custom Labels Client************************/
    client_questions: 'DOMANDE',
    client_polls: 'SONDAGGI',
    client_live_questions: 'Domande live',
    client_your_questions: 'Le tue domande',
    client_your_question: 'La tua domanda',
    client_you_did_not_send_any_question_yet: 'Non hai ancora inviato nessuna domanda.',
    client_write_a_new_question: 'Scrivi una nuova domanda',
    client_live_poll: 'Sondaggio',
    client_send_answer: 'Invia Risposta',
    client_select_language: 'Seleziona la Lingua',
    client_select_room: 'Seleziona la Sala',
    client_questions_polls: 'Domande/Sondaggi',
    client_logout: 'Esci',

    category_questions: 'DOMANDE',
    category_polls: 'SONDAGGI',
    category_main_menu: 'MENU PRINCIPALE',

    /**************************** Main Menu Client ************************/
    menu_visible: 'Visibile',
    menu_home: 'Home',
    menu_intro: 'Intro',
    menu_questions: 'Domande',
    menu_logout: 'Esci'
}