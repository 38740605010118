import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { eventActions } from '../store/actions/EventActions';
import Dropzone from 'react-dropzone';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';
import Flag from 'react-world-flags'

require("react-datepicker/dist/react-datepicker.css");

var QRCode = require('qrcode.react');

class EditModal extends Component {
    constructor(props) {
        super(props);
        let eventCode1;
        if (this.props.event) {
            eventCode1 = this.props.event.eventCode;
        } else {
            eventCode1 = "";
        }

        this.stopReload = false;

        this.customLabels = {
            lang_en: {
                questions: englishStrings.client_questions,
                polls: englishStrings.client_polls,
                live_questions: englishStrings.client_live_questions,
                your_questions: englishStrings.client_your_questions,
                your_question: englishStrings.client_your_question,
                you_did_not_send_any_question_yet: englishStrings.client_you_did_not_send_any_question_yet,
                write_a_new_question: englishStrings.client_write_a_new_question,
                live_poll: englishStrings.client_live_poll,
                send_answer: englishStrings.client_send_answer,
                select_language: englishStrings.client_select_language,
                select_room: englishStrings.client_select_room,
                logout: englishStrings.client_logout
            },
            lang_it: {
                questions: italianStrings.client_questions,
                polls: italianStrings.client_polls,
                live_questions: italianStrings.client_live_questions,
                your_questions: italianStrings.client_your_questions,
                your_question: italianStrings.client_your_question,
                you_did_not_send_any_question_yet: italianStrings.client_you_did_not_send_any_question_yet,
                write_a_new_question: italianStrings.client_write_a_new_question,
                live_poll: italianStrings.client_live_poll,
                send_answer: italianStrings.client_send_answer,
                select_language: italianStrings.client_select_language,
                select_room: italianStrings.client_select_room,
                logout: italianStrings.client_logout
            }
        }

        this.mainMenu = {
            visible: true,
            intro: true,
            home: true,
            logout: true,
            questions: true
        }

        this.state = {
            primaryColor: '',
            textColor: '',
            headerBackgroundColor: '',
            headerTextColor: '',
            backgroundColor: '',
            backgroundTextColor: '',
            codeValidity: false,
            startDateStatus: 'default',
            endDateStatus: 'default',
            startDate: '',
            endDate: '',
            eventCodeStatus: 'default',
            eventCode: eventCode1,
            inputClass: '',
            eventBoxTitle: '',
            qrButtonStatus: 'default',
            downloadStatus: 'active',
            qrImage: "/files/file-upload.png",
            primaryColorStatus: 'default',
            headerBackgroundColorStatus: 'default',
            headerTextColorStatus: 'default',
            textColorStatus: 'default',
            backgroundColorStatus: 'default',
            backgroundTextColorStatus: 'default',
            saveBtnTitle: '',
            imageCode: '',
            title: 'default',
            correctQuestionColor: 'default',
            correctQuestionColorStatus: 'default',
            autoApproveTweets: 'default',
            addedClass: 'default',
            tweetsToShow: 'default',
            addedClassTweetsToShow: 'default',
            voteResultsDisplay: 'default',
            addedClassVoteResultsDisplay: 'default',
            screenNumber: 'default',
            addedClassScreenNumber: 'default',
            optionsOrderStatus: 'default',
            showTweetsToParticipants: 'default',
            showEventInfoStatus: 'default',
            addedClassShowEventInfoStatus: 'default',
            addedClassOptionsOrderStatus: 'default',
            addedClassshowTweetsToParticipants: 'default',
            voteCounterStatus: 'default',
            addedClassVoteCounterStatus: 'default',
            hrefDownload: '',
            showHeaderBar: 'Active',
            addedClassShowHeaderBar: ' recent bg-primary ',
            automaticJumpPoll: 'Active',
            addedClassAutomaticJumpPoll: ' recent bg-primary ',

            logoImageFiles: [],
            logoPreviewStatus: 'default',
            logo: "/files/file-upload.png",
            logoStatus: 'NoImageSelected',
            logoFile: '',

            splashPath: "/files/file-upload.png",
            splashStatus: 'NoImageSelected',
            splashFile: '',
            splashScreenPreviewStatus: 'default',
            splashImageFiles: [],

            homePath: "/files/file-upload.png",
            homeStatus: 'NoImageSelected',
            homeFile: '',
            homeScreenPreviewStatus: 'default',
            homeImageFiles: [],

            themeParticipantPath: "/files/file-upload.png",
            themeParticipantStatus: 'NoImageSelected',
            themeParticipantFile: '',
            themeParticipantPreviewStatus: 'default',
            themeParticipantImageFiles: [],

            themebigScreenPath: "/files/file-upload.png",
            themebigScreenStatus: 'NoImageSelected',
            themebigScreenFile: '',
            themebigScreenPreviewStatus: 'default',
            themebigScreenImageFiles: [],

            selectedLanguage: 'default',
            italianChecked: false,
            englishChecked: false,
            showProfileToParticipants: 'default',
            showEmailToParticipants:'default',
            addedClassshowProfileToParticipants: 'default',
            addedClassShowEmailToParticipants:'default',
            availableLanguages: {lang_en: false, lang_it: true},

            customLabelsLanguage: 'lang_it',
            customLabels: this.customLabels,
            
            downloadDisable: false,

            mainMenu: this.mainMenu
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleEndChange = this.handleEndChange.bind(this);
        // this.downloadQrCode = this.downloadQrCode.bind(this);
        this.getUserPreferences = this.getUserPreferences.bind(this);
        this.saveFunction = this.saveFunction.bind(this);
        this.closeFunction = this.closeFunction.bind(this);

        this.handleUploadClick = this.handleUploadClick.bind(this);
        this.removePhoto = this.removePhoto.bind(this);
        this.removeUrlLink = this.removeUrlLink.bind(this);

        this.handleUploadImageChange = this.handleUploadImageChange.bind(this);

        this.handleDropLogoImage = this.handleDropLogoImage.bind(this);
        this.handleDropSplashImage = this.handleDropSplashImage.bind(this);
        this.handleDropHomeImage = this.handleDropHomeImage.bind(this);
        this.handleDropThemeBigScreen = this.handleDropThemeBigScreen.bind(this);
        this.handleDropThemeParticipant = this.handleDropThemeParticipant.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);
        this.handleCustomLabelsLanguageChange = this.handleCustomLabelsLanguageChange.bind(this);
    }

    componentDidMount() {
        //document.getElementById("editModal").addEventListener("hide.bs.modal", this.closeFunction);
        if (this.props.event) {
            //Called only when modal is created inside the event screen.
            this.setState({
                title: this.props.event.title,
                headerBackgroundColor: this.props.event.headerBackgroundColor,
                headerTextColor: this.props.event.headerTextColor,
                primaryColor: this.props.event.primaryColor,
                textColor: this.props.event.textColor,
                backgroundColor: this.props.event.backgroundColor,
                backgroundTextColor: this.props.event.backgroundTextColor,
                correctQuestionColor: this.props.event.correctQuestionColor,
                availableLanguages: (this.props.event.availableLanguages != null) ? this.props.event.availableLanguages : this.state.availableLanguages,
                customLabels: this.props.event.customLabels ? this.props.event.customLabels : this.state.customLabels,
                mainMenu: this.props.event.mainMenu ? this.props.event.mainMenu : this.state.mainMenu,
                hrefDownload: document.getElementById('qrCodeImage').toDataURL("image/png"),
                showHeaderBar: this.props.event.showHeaderBar ? this.props.event.showHeaderBar : this.state.showHeaderBar,
                addedClassShowHeaderBar: this.props.event.showHeaderBar === 'Active' ? ' recent bg-primary ' : '',
                automaticJumpPoll: this.props.event.automaticJumpPoll ? this.props.event.automaticJumpPoll : this.state.automaticJumpPoll,
                addedClassAutomaticJumpPoll: this.props.event.automaticJumpPoll === 'Active' ? ' recent bg-primary ' : ''
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.event === '' && this.props.event !== '') ||
            (prevProps.event.eventCode !== this.props.event.eventCode) ||
            (this.state.requireReload)) {
            //Called when new event is created after first load of application or when a new event is created, or if eventCode is changed.
            this.setState({
                eventCode: this.props.event.eventCode,
                title: this.props.event.title,
                headerBackgroundColor: this.props.event.headerBackgroundColor,
                headerTextColor: this.props.event.headerTextColor,
                primaryColor: this.props.event.primaryColor,
                textColor: this.props.event.textColor,
                backgroundColor: this.props.event.backgroundColor,
                backgroundTextColor: this.props.event.backgroundTextColor,
                correctQuestionColor: this.props.event.correctQuestionColor,
                availableLanguages: (this.props.event.availableLanguages != null) ? this.props.event.availableLanguages : this.state.availableLanguages,
                customLabels: this.props.event.customLabels ? this.props.event.customLabels : this.state.customLabels,
                mainMenu: this.props.event.mainMenu ? this.props.event.mainMenu : this.state.mainMenu,
                startDateStatus: 'default',
                endDateStatus: 'default',
                showEventInfoStatus: 'default',
                selectedLanguage: 'default',
                voteCounterStatus: 'default',
                optionsOrderStatus: 'default',
                screenNumber: 'default',
                voteResultsDisplay: 'default',
                showTweetsToParticipants: 'default',
                tweetsToShow: 'default',
                autoApproveTweets: 'default',   
                showProfileToParticipants: 'default',
                showEmailToParticipants: 'default',
                requireReload: false,
                showHeaderBar: this.props.event.showHeaderBar ? this.props.event.showHeaderBar : this.state.showHeaderBar,
                addedClassShowHeaderBar: this.props.event.showHeaderBar === 'Active' ? ' recent bg-primary ' : '',
                automaticJumpPoll: this.props.event.automaticJumpPoll ? this.props.event.automaticJumpPoll : this.state.automaticJumpPoll,
                addedClassAutomaticJumpPoll: this.props.event.automaticJumpPoll === 'Active' ? ' recent bg-primary ' : ''                
            });
        }
    }

    handleDropLogoImage(files) {
        var fileTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];
        var validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
        var FileSize = Math.round(files[0].size / 1024);
        let logoLocalPath = "/files/" + files[0].name;
        if (!fileTypes.includes(files[0].type)) {
            alert("Sorry, " + files[0].name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 2000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 100 KB.");
        } else {

            if (files[0]) {
                this.setState({
                    ...this.state,
                    logo: logoLocalPath,
                    logoFile: files[0],
                    logoStatus: 'FileSelected',
                    logoImageFiles: files.map(file => ({
                        ...file,
                        preview: URL.createObjectURL(file)
                    })),
                    logoPreviewStatus: 'drag&drop',
                });
            }
        }
    }

    handleDropSplashImage(files) {
        var fileTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];
        var validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
        var FileSize = Math.round(files[0].size / 1024);
        let splashLocalPath = "/files/" + files[0].name;
        if (!fileTypes.includes(files[0].type)) {
            //alert('The selected file is not an image. Please upload an image with the correct type.');
            alert("Sorry, " + files[0].name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 3000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 100 KB.");
        } else {
            if (files[0]) {
                this.setState({
                    ...this.state,
                    splashPath: splashLocalPath,
                    splashStatus: 'FileSelected',
                    splashFile: files[0],
                    splashImageFiles: files.map(file => ({
                        ...file,
                        preview: URL.createObjectURL(file)
                    })),
                    splashScreenPreviewStatus: 'drag&drop'

                });
            }
        }
    }

    handleDropHomeImage(files) {
        var fileTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];
        var validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
        var FileSize = Math.round(files[0].size / 1024);
        let homeLocalPath = "/files/" + files[0].name;
        if (!fileTypes.includes(files[0].type)) {
            //alert('The selected file is not an image. Please upload an image with the correct type.');
            alert("Sorry, " + files[0].name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 3000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 100 KB.");
        } else {
            if (files[0]) {
                this.setState({
                    ...this.state,
                    homePath: homeLocalPath,
                    homeStatus: 'FileSelected',
                    homeFile: files[0],
                    homeImageFiles: files.map(file => ({
                        ...file,
                        preview: URL.createObjectURL(file)
                    })),
                    homeScreenPreviewStatus: 'drag&drop'

                });
            }
        }
    }

    handleDropThemeBigScreen(files) {
        var fileTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];
        var validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
        var FileSize = Math.round(files[0].size / 1024);
        let themebigScreenPath = "/files/" + files[0].name;
        if (!fileTypes.includes(files[0].type)) {
            alert("Sorry, " + files[0].name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 2000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 100 KB.");
        } else {

            if (files[0]) {
                this.setState({
                    ...this.state,
                    themebigScreenPath,
                    themebigScreenFile: files[0],
                    themebigScreenStatus: 'FileSelected',
                    themebigScreenImageFiles: files.map(file => ({
                        ...file,
                        preview: URL.createObjectURL(file)
                    })),
                    themebigScreenPreviewStatus: 'drag&drop',
                });
            }
        }
    }

    handleDropThemeParticipant(files) {
        var fileTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];
        var validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
        var FileSize = Math.round(files[0].size / 1024);
        let themeParticipantPath = "/files/" + files[0].name;
        if (!fileTypes.includes(files[0].type)) {
            alert("Sorry, " + files[0].name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 2000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 100 KB.");
        } else {

            if (files[0]) {
                this.setState({
                    ...this.state,
                    themeParticipantPath,
                    themeParticipantFile: files[0],
                    themeParticipantStatus: 'FileSelected',
                    themeParticipantImageFiles: files.map(file => ({
                        ...file,
                        preview: URL.createObjectURL(file)
                    })),
                    themeParticipantPreviewStatus: 'drag&drop',
                });
            }
        }
    }
       

    handleUploadImageChange = (e) => {
                var preview;
                var file;
                var fileSize; 

                var validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
                var fileTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];
                let filePath = "/files/" + e.target.files[0].name;

        switch (e.target.name) {

            case "LogoImage":
                preview = document.getElementById('logoPreview');
                file = document.getElementById('inputLogo').files[0];
                fileSize = Math.round(file.size / 1024);
                
                if (!fileTypes.includes(file.type)) {
                    //alert('The selected file is not an image. Please upload an image with the correct type.');
                    alert("Sorry, " + file.name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
                } else if (fileSize > 2000) {
                    alert('The size of this file is: ' + fileSize + " KB, please choose a file less than 100 KB.");
                } else {
                    let reader = new FileReader();
                    reader.addEventListener("load", function () {
                        preview.src = reader.result;
                    }, false);

                    if (file) {
                        reader.readAsDataURL(file);
                        this.setState({
                            ...this.state,
                            logo: filePath,
                            logoFile: file,
                            logoStatus: 'FileSelected',
                            logoPreviewStatus: 'selectedFile',
                            splashScreenPreviewStatus: 'selectedFile'
                        });
                    }
                }
            break;


            case "SplashPage":
                preview = document.getElementById('imagePreviewSplash');
                file = document.getElementById('inputSplash').files[0];
                fileSize = Math.round(file.size / 1024); // 
        
                if (!fileTypes.includes(file.type)) {
                    alert("Sorry, " + file.name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
                } else if (fileSize > 3000) {
                    alert('The size of this file is: ' + fileSize + " KB, please choose a file less than 100 KB.");
                } else {
                    let reader = new FileReader();
                    reader.addEventListener("load", function () {
                        preview.src = reader.result;
                    }, false);
        
                    if (file) {
                        reader.readAsDataURL(file);
                        this.setState({
                            ...this.state,
                            splashLocalPath: filePath,
                            splashStatus: 'FileSelected',
                            splashScreenPreviewStatus: 'selectedFile',
                            splashFile: file
                        });
                    }
                }
            break;

            case "ThemeParticipant":
                preview = document.getElementById('imagePreviewthemeParticipant');
                file = document.getElementById('inputthemeParticipant').files[0];
                fileSize = Math.round(file.size / 1024); // 
    
            if (!fileTypes.includes(file.type)) {
                alert("Sorry, " + file.name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
            } else if (fileSize > 3000) {
                alert('The size of this file is: ' + fileSize + " KB, please choose a file less than 100 KB.");
            } else {
                    let reader = new FileReader();
                reader.addEventListener("load", function () {
                    preview.src = reader.result;
                }, false);
    
                if (file) {
                    reader.readAsDataURL(file);
                    this.setState({
                        ...this.state,
                        themeParticipantPath: filePath,
                        themeParticipantStatus: 'FileSelected',
                        themeParticipantPreviewStatus: 'selectedFile',
                        themeParticipantFile: file
                    });
                }
            }
            break;

            case "ThemeBigScreen":
                preview = document.getElementById('imagePreviewThemeBigScreen');
                file = document.getElementById('inputThemeBigScreen').files[0];
                fileSize = Math.round(file.size / 1024);
    
            if (!fileTypes.includes(file.type)) {
                alert("Sorry, " + file.name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
            } else if (fileSize > 3000) {
                alert('The size of this file is: ' + fileSize + " KB, please choose a file less than 100 KB.");
            } else {
                    let reader = new FileReader();
                reader.addEventListener("load", function () {
                    preview.src = reader.result;
                }, false);
    
                if (file) {
                    reader.readAsDataURL(file);
                    this.setState({
                        ...this.state,
                        themebigScreenPath: filePath,
                        themebigScreenStatus: 'FileSelected',
                        themebigScreenPreviewStatus: 'selectedFile',
                        themebigScreenFile: file
                    });
                }
            }
            break;

            
            default:
                return true;
        }
    }


    handleUploadClick() {
        document.getElementById("inputLogo").click();
    }
  

    handleChangeColor() {
        let primaryColor = document.getElementById("inputPrimaryColor").value;
        let textColor = document.getElementById("inputTextColor").value;
        let backgroundColor = document.getElementById("inputBackgroundColor").value;
        let backgroundTextColor = document.getElementById("inputBackgroundTextColor").value;
        let correctQuestionColor = document.getElementById("inputCorrectQuestionColor").value;

        let headerBackgroundColor = document.getElementById("headerBackgroundColor").value;
        let headerTextColor = document.getElementById("headerTextColor").value;

        this.setState({
            ...this.state,
            headerBackgroundColor: headerBackgroundColor,
            headerTextColor: headerTextColor,
            primaryColor: primaryColor,
            textColor: textColor,
            backgroundColor: backgroundColor,
            backgroundTextColor: backgroundTextColor,
            correctQuestionColor: correctQuestionColor,
            primaryColorStatus: 'Edited',
            textColorStatus: 'Edited',
            headerTextColorStatus: 'Edited',
            headerBackgroundColorStatus: 'Edited',
            backgroundColorStatus: 'Edited',
            correctQuestionColorStatus: 'Edited',
            backgroundTextColorStatus: 'Edited',
        });
    }


    //Handles Input Changes
    handleEventChange = (e) => {
        if (e.target.name === 'eventCode') {
            let value = e.target.value.toLowerCase();
            this.props.checkEventCode(this.props.event, value, callback => {
                if (callback === 'codeNotAvailable') {
                    // we set the state of the input invalid
                    this.setState({
                        ...this.state,
                        eventCode: value,
                        inputClass: "is-invalid",
                        eventCodeStatus: "validating"
                    });
                } else {
                    this.setState({
                        ...this.state,
                        eventCode: value,
                        eventCodeStatus: 'validated',
                        qrButtonStatus: 'validated',
                        inputClass: "",
                    });
                }
            });
        }
        if (e.target.name === 'eventTitle') {
            this.setState({...this.state, title: e.target.value});
        }

        if (e.target.name === 'eventLanguageEnglish') {
            let value = e.target.checked;

            //Keep language selected if it is the only one selected.
            if (value == false && this.state.availableLanguages.lang_it == false) {
                value = true;
            }

            this.setState(prevState => ({
                availableLanguages: {
                    ...prevState.availableLanguages,
                    lang_en: value
                }
            }));
        }

        if (e.target.name === 'eventLanguageItalian') {
            let value = e.target.checked;

            //Keep language selected if it is the only one selected.
            if (value == false && this.state.availableLanguages.lang_en == false) {
                value = true;
            }

            this.setState(prevState => ({
                availableLanguages: {
                    ...prevState.availableLanguages,
                    lang_it: value
                }
            }));
        }

        if (e.target.name.includes('customLabel-')) {
            let editCustomLabels = this.state.customLabels;
            editCustomLabels[this.state.customLabelsLanguage][e.target.name.replace('customLabel-', '')] = e.target.value;
            this.setState({customLabels: editCustomLabels});
        }
    }

    handleCustomLabelsLanguageChange = e => {
        this.setState({
            customLabelsLanguage: e.target.value
        });

    }

    disabledDate(args) {
        if (args.date.getDay() === 0 || args.date.getDay() === 6) {
            //set 'true' to disable the weekends
            args.isDisabled = true;
        }
    }

    checkCustomLabels() {
        for (let lang in this.customLabels) {
            for (let label in this.customLabels[lang]) {
                if (this.state.customLabels[lang][label] === '') {
                    this.state.customLabels[lang][label] = this.customLabels[lang][label];
                } else if (!this.state.customLabels[lang][label]) {
                    this.state.customLabels[lang][label] = this.customLabels[lang][label];
                }
            }
        }
    }

    checkMainMenu() {
        this.setState({
            mainMenu: {
                ...this.mainMenu,
                ...this.state.mainMenu
            }
        });
    }
       
    saveFunction(close = false) {

        console.log("Edit Modal Save Function");
        let dtmStart = this.state.startDateStatus === 'Edited' ? this.state.startDate : this.props.event.dtmStart;
        let dtmEnd = this.state.endDateStatus === 'Edited' ? this.state.endDate : this.props.event.dtmEnd;

        //Check if startDate is subsequent to endDate. In case, move endDate to new startDate.
        let dtmStartObj = moment(dtmStart, "MM/DD/YYYY");
        let dtmEndObj = moment(dtmEnd, "MM/DD/YYYY");
        if (dtmEndObj.diff(dtmStartObj, 'days') < 0) {
            dtmEnd = dtmStart;
        }

        var screenNumber;
        var votesDisplay;
        var approvedTweets;
        var moderatorTweetSwitch;
        var voteResultsDisplay;
        var optionsOrderStatus;
        var showTweetsToParticipants;
        var showEventInfoStatus;
        var showHeaderBar;
        var voteCounterStatus;
        var selectedLanguage;
        var showProfileToParticipants;
        var showEmailToParticipants;
        var automaticJumpPoll;

        if (this.state.tweetsToShow === 'default') {
            approvedTweets = this.props.event.tweetsToShow;
        } else {
            approvedTweets = this.state.tweetsToShow;
        }

        if (this.state.autoApproveTweets === 'default') {
            moderatorTweetSwitch = this.props.event.autoApproveTweets;
        } else {
            moderatorTweetSwitch = this.state.autoApproveTweets;
        }


        if (this.state.voteResultsDisplay === 'default') {
            voteResultsDisplay = this.props.event.voteResultsDisplay;
        } else {
            voteResultsDisplay = this.state.voteResultsDisplay;
        }

        if (this.state.screenNumber === 'default') {
            screenNumber = this.props.event.screenNumber;
        } else {
            screenNumber = this.state.screenNumber;
        }

        if (this.state.optionsOrderStatus === 'default') {
            optionsOrderStatus = this.props.event.optionsOrderStatus;
        } else {
            optionsOrderStatus = this.state.optionsOrderStatus;
        }


        if (this.state.showTweetsToParticipants === 'default') {
            showTweetsToParticipants = this.props.event.showTweetsToParticipants;
        } else {
            showTweetsToParticipants = this.state.showTweetsToParticipants;
        }

        if (this.state.showProfileToParticipants === 'default') {
            showProfileToParticipants = this.props.event.showProfileToParticipants;
        } else {
            showProfileToParticipants = this.state.showProfileToParticipants;
        }
        
        if (this.state.showEmailToParticipants === 'default') {
            showEmailToParticipants = this.props.event.showEmailToParticipants;
        } else {
            showEmailToParticipants = this.state.showEmailToParticipants;
        }

        if (this.state.showEventInfoStatus === 'default') {
            showEventInfoStatus = this.props.event.showEventInfoStatus;
        } else {
            showEventInfoStatus = this.state.showEventInfoStatus;
        }

        if (this.state.voteCounterStatus === 'default') {
            voteCounterStatus = this.props.event.voteCounterStatus;
        } else {
            voteCounterStatus = this.state.voteCounterStatus;
        }

        if (this.state.selectedLanguage === 'default') {
            selectedLanguage = this.props.event.selectedLanguage;
        } else {
            selectedLanguage = this.state.selectedLanguage;
        }
       
        if (this.state.showHeaderBar === 'default') {
            showHeaderBar = this.props.event.showHeaderBar;
        } else {
            showHeaderBar = this.state.showHeaderBar;
        }

        if (this.state.automaticJumpPoll === 'default') {
            automaticJumpPoll = this.props.event.automaticJumpPoll;
        } else {
            automaticJumpPoll = this.state.automaticJumpPoll;
        }

        this.setState({
            eventCode: this.state.eventCode.trim(),
            hrefDownload: document.getElementById('qrCodeImage').toDataURL("image/png")
        });

        this.checkCustomLabels();
        this.checkMainMenu();

        console.log("Save Function", this.props.event.homeImageUrl);

        let eventData = {
            id: this.props.event.id,
            title: this.state.title !== 'default' && this.state.title !== '' ? this.state.title : this.props.event.title,
            dtmStart: dtmStart,
            dtmEnd: dtmEnd,
            eventCode: this.state.eventCode,
            primaryColor: this.state.primaryColor,
            headerBackgroundColor: this.state.headerBackgroundColor,
            headerTextColor: this.state.headerTextColor,
            correctQuestionColor: this.state.correctQuestionColor,
            textColor: this.state.textColor,
            backgroundColor: this.state.backgroundColor,
            backgroundTextColor: this.state.backgroundTextColor,
            screenNumber: screenNumber,
            voteResultsDisplay: voteResultsDisplay,

            eventLogoUrl: this.props.event.eventLogoUrl,
            splashImageUrl: this.props.event.splashImageUrl,
            homeImageUrl: this.props.event.homeImageUrl !== undefined ? this.props.event.homeImageUrl : 'default',
            themeBigScreenImageUrl: this.props.event.themeBigScreenImageUrl,
            themeParticipantImageUrl: this.props.event.themeParticipantImageUrl,

            currentLiveSession: this.props.event.currentLiveSession,
            currentSelectedLiveSession: this.props.event.currentSelectedLiveSession,
            bigScreenStatus: this.props.event.bigScreenStatus,
            currentQuestion: this.props.event.currentQuestion,
            currentQuestionElement: this.props.event.currentQuestionElement,
            currentTweetElement: this.props.event.currentTweetElement,
            currentSurveyElement: this.props.event.currentSurveyElement,
            //we use the props because in the event actions of creating the event we have defined the default
            tweetsToShow: approvedTweets,
            autoApproveTweets: moderatorTweetSwitch,
            optionsOrderStatus: optionsOrderStatus,
            showTweetsToParticipants: showTweetsToParticipants,
            showEventInfoStatus: showEventInfoStatus,
            voteCounterStatus: voteCounterStatus,
            eventOrderIndex: this.props.event.eventOrderIndex,
            showCorrectAnswerColor: this.props.event.showCorrectAnswerColor,
            selectedLanguage: selectedLanguage,
            showProfileToParticipants: showProfileToParticipants,
            showEmailToParticipants:showEmailToParticipants,
            participantsNumberLimit:this.props.event.participantsNumberLimit,
            eventStatus: this.props.event.eventStatus,
            availableLanguages: this.state.availableLanguages,
            customLabels: this.state.customLabels,
            mainMenu: this.state.mainMenu,
            showHeaderBar: showHeaderBar,
            automaticJumpPoll: automaticJumpPoll,
        };

        this.props.onSaveEvent(this.props.user.userId, eventData, callback => {
           
            console.log("Save Event callback");

            if (this.state.logoStatus !== "NoImageSelected" && this.state.splashStatus !== "NoImageSelected") {
                var imageFiles = { "SPLASH": this.state.splashFile, "LOGO": this.state.logoFile };
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "SPLASH&LOGO", imageFiles, callbackLogo => {
                });
            } else if (this.state.logoStatus !== "NoImageSelected") {
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "LOGO", this.state.logoFile, callbackLogo => {
                });
            } else if (this.state.splashStatus !== "NoImageSelected") {
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "SPLASH", this.state.splashFile, callbackLogo => {
                });
            }

            //we need also to update the firebase if the user has been removed the images and going back to the default status there
            if (this.state.logoPreviewStatus === "removePhoto" && this.state.splashScreenPreviewStatus === "removePhoto") {
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "SPLASH&LOGO-REMOVE", "default", callbackLogo => {
                });
            } else if (this.state.logoPreviewStatus === "removePhoto") {
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "LOGO-REMOVE", "default", callbackLogo => {
                });
            } else if (this.state.splashScreenPreviewStatus === "removePhoto") {
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "SPLASH-REMOVE", "default", callbackLogo => {
                });
            }


            if (this.state.themebigScreenStatus !== "NoImageSelected" && this.state.themeParticipantStatus !== "NoImageSelected") {
                var imageFilesThemes = {
                    "ThemeParticipant": this.state.themeParticipantFile,
                    "ThemeBigscreen": this.state.themebigScreenFile
                };
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "ThemeParticipant&BigScreen", imageFilesThemes, callbackLogo => {
                });
            } else if (this.state.themebigScreenStatus !== "NoImageSelected") {
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "ThemeBigscreen", this.state.themebigScreenFile, callbackLogo => {
                });
            } else if (this.state.themeParticipantStatus !== "NoImageSelected") {
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "ThemeParticipant", this.state.themeParticipantFile, callbackLogo => {
                });
            }

            //we need also to update the firebase if the user has been removed the images and going back to the default status there
            if (this.state.themeParticipantPreviewStatus === "removePhoto" && this.state.themebigScreenPreviewStatus === "removePhoto") {
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "ThemeParticipant&BigScreen-REMOVE", "default", callbackLogo => {
                });
            } else if (this.state.themebigScreenPreviewStatus === "removePhoto") {
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "ThemeBigscreen-REMOVE", "default", callbackLogo => {
                });
            } else if (this.state.themeParticipantPreviewStatus === "removePhoto") {
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "ThemeParticipant-REMOVE", "default", callbackLogo => {
                });
            }

            console.log("homestatus: " + this.state.homeStatus);
            if (this.state.homeStatus !== "NoImageSelected") {
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "HOME", this.state.homeFile, callbackLogo => {
                });
            }
            //we need also to update the firebase if the user has been removed the images and going back to the default status there
            if (this.state.homeScreenPreviewStatus === "removePhoto") {
                console.log("save remove photo HOME");
                this.props.onUploadEventLogo(this.props.user.userId, callback.id, "HOME-REMOVE", "default", callbackLogo => {
                });
            }

            //We need to reset the modal's content after saving its data so in the next time it will show the updated data
            /*
            if (document.getElementById('eventModalsForm')) {
                document.getElementById('eventModalsForm').reset();
            }
            */

            /*this.setState({
                ...this.state, ...this.state,
                startDateStatus: 'default',
                endDateStatus: 'default',
                startDate: '',
                endDate: '',
                eventCodeStatus: 'default',
                primaryColorStatus: 'default',
                selectedLanguage: 'default',
                headerBackgroundColorStatus: 'default',
                headerTextColorStatus: 'default',
                textColorStatus: 'default',
                backgroundColorStatus: 'default',
                correctQuestionColorStatus: 'default',
                backgroundTextColorStatus: 'default',
                
                title: 'default',
                autoApproveTweets: 'default',
                tweetsToShow: 'default',
                screenNumber: 'default',
                voteResultsDisplay: 'default',
                optionsOrderStatus: 'default',
                showTweetsToParticipants: 'default',
                showEventInfoStatus: 'default',
                voteCounterStatus: 'default',
                addedClassVoteCounterStatus: 'default',

                logoPreviewStatus: 'default',
                logoStatus: 'NoImageSelected',

                splashStatus: 'NoImageSelected',
                splashScreenPreviewStatus: 'default',

                // themeParticipantPath: "/files/file-upload.png",
                // themeParticipantFile: '',
                // themeParticipantImageFiles: [],

                themeParticipantPreviewStatus: 'default',
                themeParticipantStatus: 'NoImageSelected',

               
                // themebigScreenPath: "/files/file-upload.png",
                // themebigScreenImageFiles: [],
                // themebigScreenFile: '',

                themebigScreenPreviewStatus: 'default',
                themebigScreenStatus: 'NoImageSelected',

                showProfileToParticipants: 'default',
                showEmailToParticipants:'default',
                customLabelsLanguage: close ? 'lang_it' : this.state.customLabelsLanguage
            });*/
            });
    }

    checkInputStatuses(close, e) {

        e.preventDefault();
        if (this.state.title === "" || this.state.title === "default") {
            alert("Please define a title for this event");
        } else {
            //var eventCode = document.getElementById("inputCode" + this.props.event.id).value.trim();
            // check the code availability inside the firebase it is also sensitive to the upercase for example event will be different than Event!
            this.props.checkEventCode(this.props.event, this.state.eventCode, callback => {
                if (callback === 'codeNotAvailable') {
                    // we set the state of the input invalid
                    this.setState({
                        ...this.state,
                        inputClass: "is-invalid",
                    });
                } else {
                    //var updatedEvent = this.props.event;
                    //updatedEvent.eventCode = eventCode;
                    //  this.props.saveFunction(updatedEvent);
                    this.saveFunction(close);
                    //We call the close button to close the modal
                    // document.getElementById("hidePopUpBtn").click();

                    // we update the QR code also and change the status of the update Qr code btn
                    this.setState({
                        ...this.state,
                        eventCodeStatus: 'validated',
                        qrButtonStatus: 'default',
                        inputClass: ""
            });

                    if (close) {
                    //We call the close button to close the modal
                        this.stopReload = true;
                    document.getElementById("hidePopUpBtn").click();
                }
                }
            });
        }
    }

    closeFunction() {
        if (this.props.event.id === 0) {
        document.getElementById('eventModalsForm').reset();
        this.setState({
            ...this.state,
            startDateStatus: 'default',
            endDateStatus: 'default',
            startDate: '',
            endDate: '',
            eventCodeStatus: 'default',
            inputClass: '',
            qrButtonStatus: 'default',
            primaryColorStatus: 'default',
            selectedLanguage: 'default',
            textColorStatus: 'default',
            backgroundColorStatus: 'default',
            correctQuestionColorStatus: 'default',
            backgroundTextColorStatus: 'default',
                title: 'default',
            autoApproveTweets: 'default',
            tweetsToShow: 'default',
            screenNumber: 'default',
            voteResultsDisplay: 'default',
            optionsOrderStatus: 'default',
            showTweetsToParticipants: 'default',
            showEventInfoStatus: 'default',
            voteCounterStatus: 'default',
            addedClassVoteCounterStatus: 'default',
            logoPreviewStatus: 'default',
            splashScreenPreviewStatus: 'default',

                homePath: "/files/file-upload.png",
                homeStatus: 'NoImageSelected',
                homeFile: '',
                homeScreenPreviewStatus: 'default',
                homeImageFiles: [],                

            themeParticipantPath: "/files/file-upload.png",
                themeParticipantStatus: 'NoImageSelected',
            themeParticipantFile: '',
            themeParticipantPreviewStatus: 'default',
            themeParticipantImageFiles: [],

            themebigScreenPath: "/files/file-upload.png",
                themebigScreenStatus: 'NoImageSelected',
            themebigScreenFile: '',
            themebigScreenPreviewStatus: 'default',
            themebigScreenImageFiles: [],
            showProfileToParticipants: 'default',
            showEmailToParticipants:'default',
                customLabelsLanguage: 'lang_it',
                downloadDisable: false,

                showHeaderBar: 'default',
                automaticJumpPoll: 'default',
        });
        }
        else {
            
            if (!this.stopReload) {
                document.getElementById('eventModalsForm').reset();
                this.setState({
                    ...this.state,
                    /*
                    availableLanguages: (this.props.event.availableLanguages != null) ? this.props.event.availableLanguages : this.state.availableLanguages,
                    primaryColorStatus: 'default',
                    textColorStatus: 'default',
                    headerTextColorStatus: 'default',
                    headerBackgroundColorStatus: 'default',
                    backgroundColorStatus: 'default',
                    correctQuestionColorStatus: 'default',
                    backgroundTextColorStatus: 'default',
                    */
                    requireReload: true
                });
            }
            else {
                this.stopReload = false;
            }

        }
    }

    handleChange(date) {
        this.setState({
            ...this.state,
            startDate: date.format("MM/DD/YYYY"),
            startDateStatus: 'Edited'
        });
    }

    handleEndChange(date) {
        this.setState({
            ...this.state,
            endDate: date.format("MM/DD/YYYY"),
            endDateStatus: 'Edited'
        });
    }

    getUserPreferences(option) {

        switch (option) { 
            case 'visibleMainMenu':
                this.setState({
                    mainMenu: {
                        ...this.state.mainMenu,
                        visible: !this.state.mainMenu.visible
                    }
                });
                break;

            case 'introMainMenu':
                this.setState({
                    mainMenu: {
                        ...this.state.mainMenu,
                        intro: !this.state.mainMenu.intro
                    }
                });
                break;

            case 'homeMainMenu':
                this.setState({
                    mainMenu: {
                        ...this.state.mainMenu,
                        home: !this.state.mainMenu.home
                    }
                });
                break;

            case 'questionsMainMenu':
                this.setState({
                    mainMenu: {
                        ...this.state.mainMenu,
                        questions: !this.state.mainMenu.questions
                    }
                });
                break;

            case 'logoutMainMenu':
                this.setState({
                    mainMenu: {
                        ...this.state.mainMenu,
                        logout: !this.state.mainMenu.logout
                    }
                });
                break;

            case "VoteCounterStatus":
                if (this.state.voteCounterStatus === 'default' && this.props.event.voteCounterStatus === 'Active') {
                    this.setState({ ...this.state, voteCounterStatus: 'Disable', addedClassVoteCounterStatus: "  " });
                } else if (this.state.voteCounterStatus === 'default' && this.props.event.voteCounterStatus === 'Disable') {
                    this.setState({
                        ...this.state,
                        voteCounterStatus: 'Active',
                        addedClassVoteCounterStatus: " recent bg-primary "
                    });
                } else if (this.state.voteCounterStatus === 'Active') {
                    this.setState({ ...this.state, voteCounterStatus: 'Disable', addedClassVoteCounterStatus: " " });
                } else {
                    this.setState({
                        ...this.state,
                        voteCounterStatus: 'Active',
                        addedClassVoteCounterStatus: "  recent bg-primary  "
                    });
                }
                break;

            case "OptionsOrderStatus":
                if (this.state.optionsOrderStatus === 'default' && this.props.event.optionsOrderStatus === 'Active') {
                    this.setState({ ...this.state, optionsOrderStatus: 'Disable', addedClassOptionsOrderStatus: "  " });
                } else if (this.state.optionsOrderStatus === 'default' && this.props.event.optionsOrderStatus === 'Disable') {
                    this.setState({
                        ...this.state,
                        optionsOrderStatus: 'Active',
                        addedClassOptionsOrderStatus: " recent bg-primary "
                    });
                } else if (this.state.optionsOrderStatus === 'Active') {
                    this.setState({ ...this.state, optionsOrderStatus: 'Disable', addedClassOptionsOrderStatus: " " });
                } else {
                    this.setState({
                        ...this.state,
                        optionsOrderStatus: 'Active',
                        addedClassOptionsOrderStatus: "  recent bg-primary  "
                    });
                }
                break;

            case "showTweetsToParticipants":
                if (this.state.showTweetsToParticipants === 'default' && this.props.event.showTweetsToParticipants === 'No') {
                    this.setState({
                        ...this.state,
                        showTweetsToParticipants: 'Yes',
                        addedClassshowTweetsToParticipants: " recent bg-primary "
                    });
                } else if (this.state.showTweetsToParticipants === 'default' && this.props.event.showTweetsToParticipants === 'Yes') {
                    this.setState({
                        ...this.state,
                        showTweetsToParticipants: 'No',
                        addedClassshowTweetsToParticipants: "  "
                    });
                } else if (this.state.showTweetsToParticipants === 'No') {
                    this.setState({
                        ...this.state,
                        showTweetsToParticipants: 'Yes',
                        addedClassshowTweetsToParticipants: " recent bg-primary "
                    });
                } else {
                    this.setState({
                        ...this.state,
                        showTweetsToParticipants: 'No',
                        addedClassshowTweetsToParticipants: "    "
                    });
                }
                break;

            case "showEventInfoStatus":
                if (this.state.showEventInfoStatus === 'default' && this.props.event.showEventInfoStatus === 'Active') {
                    this.setState({ ...this.state, showEventInfoStatus: 'Disable', addedClassShowEventInfoStatus: "  " });
                } else if (this.state.showEventInfoStatus === 'default' && this.props.event.showEventInfoStatus === 'Disable') {
                    this.setState({
                        ...this.state,
                        showEventInfoStatus: 'Active',
                        addedClassShowEventInfoStatus: " recent bg-primary "
                    });
                } else if (this.state.showEventInfoStatus === 'Active') {
                    this.setState({ ...this.state, showEventInfoStatus: 'Disable', addedClassShowEventInfoStatus: " " });
                } else {
                    this.setState({
                        ...this.state,
                        showEventInfoStatus: 'Active',
                        addedClassShowEventInfoStatus: "  recent bg-primary  "
                    });
                }
                break;

            case "ScreenNumber":
                if (this.state.screenNumber === 'default' && this.props.event.screenNumber === 'One') {
                    this.setState({ ...this.state, screenNumber: 'Two', addedClassScreenNumber: " recent bg-primary " });
                } else if (this.state.screenNumber === 'default' && this.props.event.screenNumber === 'Two') {
                    this.setState({ ...this.state, screenNumber: 'One', addedClassScreenNumber: " " });
                } else if (this.state.screenNumber === 'One') {
                    this.setState({ ...this.state, screenNumber: 'Two', addedClassScreenNumber: " recent bg-primary " });
                } else {
                    this.setState({ ...this.state, screenNumber: 'One', addedClassScreenNumber: "  " });
                }
                break;

            case "VoteResultsDisplay":
                if (this.state.voteResultsDisplay === 'default' && this.props.event.voteResultsDisplay === 'Percentage') {
                    this.setState({
                        ...this.state,
                        voteResultsDisplay: 'Numbers',
                        addedClassVoteResultsDisplay: " recent bg-primary "
                    });
                } else if (this.state.voteResultsDisplay === 'default' && this.props.event.voteResultsDisplay === 'Numbers') {
                    this.setState({ ...this.state, voteResultsDisplay: 'Percentage', addedClassVoteResultsDisplay: " " });
                } else if (this.state.voteResultsDisplay === 'Percentage') {
                    this.setState({
                        ...this.state,
                        voteResultsDisplay: 'Numbers',
                        addedClassVoteResultsDisplay: " recent bg-primary "
                    });
                } else {
                    this.setState({
                        ...this.state,
                        voteResultsDisplay: 'Percentage',
                        addedClassVoteResultsDisplay: "  "
                    });
                }
                break;

            case 'AutoApproved':
                if (this.state.autoApproveTweets === 'default' && this.props.event.autoApproveTweets === 'With Moderator') {
                    this.setState({ ...this.state, autoApproveTweets: 'Without Moderator', addedClass: "  " });
                } else if (this.state.autoApproveTweets === 'default' && this.props.event.autoApproveTweets === 'Without Moderator') {
                    this.setState({
                        ...this.state,
                        autoApproveTweets: 'With Moderator',
                        addedClass: " recent bg-primary "
                    });
                } else if (this.state.autoApproveTweets === 'Without Moderator') {
                    this.setState({
                        ...this.state,
                        autoApproveTweets: 'With Moderator',
                        addedClass: " recent bg-primary "
                    });
                } else {
                    this.setState({ ...this.state, autoApproveTweets: 'Without Moderator', addedClass: "  " });
                }
                break;

            case 'TweetsToShow':
                if (this.state.tweetsToShow === 'default' && this.props.event.tweetsToShow === 'Approved') {
                    this.setState({ ...this.state, tweetsToShow: 'Live', addedClassTweetsToShow: " recent bg-primary " });
                } else if (this.state.tweetsToShow === 'default' && this.props.event.tweetsToShow === 'Live') {
                    this.setState({ ...this.state, tweetsToShow: 'Approved', addedClassTweetsToShow: "  " });
                } else if (this.state.tweetsToShow === 'Live') {
                    this.setState({ ...this.state, tweetsToShow: 'Approved', addedClassTweetsToShow: "  " });
                } else {
                    this.setState({ ...this.state, tweetsToShow: 'Live', addedClassTweetsToShow: " recent bg-primary " });
                }
                break;

            case 'selectedLanguage':
                if (this.state.selectedLanguage === 'English') {
                    this.setState({
                        ...this.state,
                        selectedLanguage: 'Italian',
                        englishChecked: false,
                        italianChecked: true
                    });
                } else {
                    this.setState({
                        ...this.state,
                        selectedLanguage: 'English',
                        englishChecked: true,
                        italianChecked: false
                    });
                }
                break;

            case "showProfileToParticipants":
                if (this.state.showProfileToParticipants === 'default' && this.props.event.showProfileToParticipants === 'No') {
                    this.setState({
                        ...this.state,
                        showProfileToParticipants: 'Yes',
                        addedClassshowProfileToParticipants: " recent bg-primary "
                    });
                } else if (this.state.showProfileToParticipants === 'default' && this.props.event.showProfileToParticipants === 'Yes') {
                    this.setState({
                        ...this.state,
                        showProfileToParticipants: 'No',
                        addedClassshowProfileToParticipants: "  "
                    });
                } else if (this.state.showProfileToParticipants === 'Yes') {
                    this.setState({
                        ...this.state,
                        showProfileToParticipants: 'No',
                        addedClassshowProfileToParticipants: "  "
                    });
                } else {
                    this.setState({
                        ...this.state,
                        showProfileToParticipants: 'Yes',
                        addedClassshowProfileToParticipants: " recent bg-primary "
                    });
                }
                break;
                
            case "showEmailToParticipants":
                if (this.state.showEmailToParticipants === 'default' && this.props.event.showEmailToParticipants === 'No') {
                    this.setState({
                        ...this.state,
                        showEmailToParticipants: 'Yes',
                        addedClassShowEmailToParticipants: " recent bg-primary "
                    });
                } else if (this.state.showEmailToParticipants === 'default' && this.props.event.showEmailToParticipants === 'Yes') {
                    this.setState({
                        ...this.state,
                        showEmailToParticipants: 'No',
                        addedClassShowEmailToParticipants: "  "
                    });
                } else if (this.state.showEmailToParticipants === 'Yes') {
                    this.setState({
                        ...this.state,
                        showEmailToParticipants: 'No',
                        addedClassShowEmailToParticipants: "  "
                    });
                } else {
                    this.setState({
                        ...this.state,
                        showEmailToParticipants: 'Yes',
                        addedClassShowEmailToParticipants: " recent bg-primary "
                    });
                }
                break;

            case "agendaLinkAddress":
                let agendaLinkAddress = document.getElementById('agendaLinkAddress').value;
                if (agendaLinkAddress !="") {
                    this.setState({ ...this.state, agendaLinkAddressState: agendaLinkAddress });
                } 
                break;

            case "surveyLinkAddress":
                let surveyLinkAddress = document.getElementById('surveyLinkAddress').value;
                if (surveyLinkAddress !="") {
                    this.setState({ ...this.state, surveyLinkAddressState: surveyLinkAddress });
                } 
                break;

            case "showHeaderBar":
                if (this.state.showHeaderBar === 'default' && this.props.event.showHeaderBar === 'Active') {
                    this.setState({ ...this.state, showHeaderBar: 'Disable', addedClassShowHeaderBar: "  " });
                } else if (this.state.showHeaderBar === 'default' && this.props.event.showHeaderBar === 'Disable') {
                    this.setState({
                        ...this.state,
                        showHeaderBar: 'Active',
                        addedClassShowHeaderBar: " recent bg-primary "
                    });
                } else if (this.state.showHeaderBar === 'Active') {
                    this.setState({ ...this.state, showHeaderBar: 'Disable', addedClassShowHeaderBar: " " });
                } else {
                    this.setState({
                        ...this.state,
                        showHeaderBar: 'Active',
                        addedClassShowHeaderBar: "  recent bg-primary  "
                    });
                }
                break;

            case "automaticJumpPoll":
                if (this.state.automaticJumpPoll === 'default' && this.props.event.automaticJumpPoll === 'Active') {
                    this.setState({ ...this.state, automaticJumpPoll: 'Disable', addedClassAutomaticJumpPoll: "  " });
                } else if (this.state.automaticJumpPoll === 'default' && this.props.event.automaticJumpPoll === 'Disable') {
                    this.setState({
                        ...this.state,
                        automaticJumpPoll: 'Active',
                        addedClassAutomaticJumpPoll: " recent bg-primary "
                    });
                } else if (this.state.automaticJumpPoll === 'Active') {
                    this.setState({ ...this.state, automaticJumpPoll: 'Disable', addedClassAutomaticJumpPoll: " " });
                } else {
                    this.setState({
                        ...this.state,
                        automaticJumpPoll: 'Active',
                        addedClassAutomaticJumpPoll: "  recent bg-primary  "
                    });
                }
                break;

            default:
                return true;

        }
    }

    removePhoto(imageType) {
        console.log("removePhoto", imageType);
        if (imageType === "LOGO") {
            this.setState({ ...this.state, logoPreviewStatus: "removePhoto", logoStatus: "NoImageSelected" });
        } else if (imageType === "SPLASH") {
            this.setState({ ...this.state, splashScreenPreviewStatus: "removePhoto", splashStatus: "NoImageSelected" });
        } else if (imageType === "HOME") {
            this.setState({ ...this.state, homeScreenPreviewStatus: "removePhoto", homeStatus: "NoImageSelected" });
        } else if (imageType === "ThemeParticipant") {
            this.setState({
                ...this.state,
                themeParticipantPreviewStatus: "removePhoto",
                themeParticipantStatus: "NoImageSelected"
            });
        }else if (imageType === "ThemeBigScreen") {
            this.setState({
                ...this.state,
                themebigScreenPreviewStatus: "removePhoto",
                themebigScreenStatus: "NoImageSelected"
            });
        }
    }

    removeUrlLink(type){
        if(type === "survey"){
            this.setState({ ...this.state, surveyLinkAddressState: "removed" });
        }else if(type === "agenda"){
            this.setState({ ...this.state, agendaLinkAddressState: "removed" });
        } 
    }

    render() {
        var objCurrEventStartDate = moment(this.props.event.dtmStart, "MM/DD/YYYY");
        var objCurrEventEndDate = moment(this.props.event.dtmEnd, "MM/DD/YYYY");

        var selectedLanguageStrings;
        if (this.props.event && this.props.event.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        var selectedLanguageStateEnglish;
        var selectedLanguageStateItalian;

        if (this.state.selectedLanguage === "default") {

            if (this.props.event && this.props.event.selectedLanguage === "English") {
                selectedLanguageStateEnglish = true;
                selectedLanguageStateItalian = false;
            } else {
                selectedLanguageStateEnglish = false;
                selectedLanguageStateItalian = true;
            }
        }

        const { logoImageFiles } = this.state;
        const { splashImageFiles } = this.state;
        const { homeImageFiles } = this.state;
        const { themebigScreenImageFiles } = this.state;
        const { themeParticipantImageFiles } = this.state;
        let logoPreviewComponent;
        let imageSplashPreviewComponent;
        let imageHomePreviewComponent;
        let imageThemeBigScreenComponent;
        let imageThemeParticipantComponent;

        if (this.state.logoPreviewStatus === 'drag&drop') {
            logoPreviewComponent = (
                <div>{logoImageFiles.map((file) => <img src={file.preview} id="logoPreview"
                                                    className='img-fluid img-thumbnail logoEditModalImage'/>)}</div>
            )
        } else if (this.state.logoPreviewStatus === 'removePhoto') {
            logoPreviewComponent = (
                <img src={"/files/LogoTemp.png"} id="logoPreview" className='img-fluid img-thumbnail logoEditModalImage'
                     style={{backgroundColor: '#e6e6e6'}}/>
            )
        } else {
            logoPreviewComponent = (
                <img src={"/files/file-upload.png"} id="logoPreview"
                     className='img-fluid img-thumbnail logoEditModalImage' style={{backgroundColor: '#e6e6e6'}}/>
            )
        }

        if (this.state.splashScreenPreviewStatus === 'drag&drop') {
            imageSplashPreviewComponent = (
                <div>{splashImageFiles.map((file, i) => <img key={i} src={file.preview} id="imagePreviewSplash"
                                                          className='splashEditModalImage'
                                                          style={{backgroundColor: '#e6e6e6'}}/>)}</div>
            )
        } else if (this.state.splashScreenPreviewStatus === 'removePhoto') {
            imageSplashPreviewComponent = (
                <img src={"/files/file-upload.png"} id="imagePreviewSplash" className='splashEditModalImage'
                     style={{backgroundColor: '#e6e6e6'}}/>
            )
        } else {
            imageSplashPreviewComponent = (
                <img src={"/files/file-upload.png"} id="imagePreviewSplash" className='splashEditModalImage'
                     style={{backgroundColor: '#e6e6e6'}}/>
            )
        }

        if (this.state.homeScreenPreviewStatus === 'drag&drop') {
            imageHomePreviewComponent = (
                <div>{homeImageFiles.map((file, i) => <img key={i} src={file.preview} id="imagePreviewHome"
                    className='homeEditModalImage'
                    style={{ backgroundColor: '#e6e6e6' }} />)}</div>
            )
        } else if (this.state.homeScreenPreviewStatus === 'removePhoto') {
            imageHomePreviewComponent = (
                <img src={"/files/file-upload.png"} id="imagePreviewHome" className='homeEditModalImage'
                    style={{ backgroundColor: '#e6e6e6' }} />
            )
        } else {
            imageHomePreviewComponent = (
                <img src={"/files/file-upload.png"} id="imagePreviewHome" className='homeEditModalImage'
                    style={{ backgroundColor: '#e6e6e6' }} />
            )
        }

        if (this.state.themebigScreenPreviewStatus === 'drag&drop') {
            imageThemeBigScreenComponent = (
                <div>{themebigScreenImageFiles.map((file, i) => <img key={i} src={file.preview} id="imagePreviewThemeBigScreen"
                                                                  className='imagePreviewThemeBigScreen'
                                                                  style={{backgroundColor: '#e6e6e6'}}/>)}</div>
            )
        } else if (this.state.themebigScreenPreviewStatus === 'removePhoto') {
            imageThemeBigScreenComponent = (
                <img src={"/files/file-upload.png"} id="imagePreviewThemeBigScreen"
                     className='imagePreviewThemeBigScreen' style={{backgroundColor: '#e6e6e6'}}/>
            )
        } else {
            imageThemeBigScreenComponent = (
                <img src={"/files/file-upload.png"} id="imagePreviewThemeBigScreen"
                     className='imagePreviewThemeBigScreen' style={{backgroundColor: '#e6e6e6'}}/>
            )
        }

        if (this.state.themeParticipantPreviewStatus === 'drag&drop') {
            imageThemeParticipantComponent = (
                <div>{themeParticipantImageFiles.map((file, i) => <img key={i} src={file.preview} id="imagePreviewThemeParticipant"
                                                                    className='imagePreviewThemeParticipant'
                                                                    style={{backgroundColor: '#e6e6e6'}}/>)}</div>
            )
        } else if (this.state.themeParticipantPreviewStatus === 'removePhoto') {
            imageThemeParticipantComponent = (
                <img src={"/files/file-upload.png"} id="imagePreviewThemeParticipant"
                     className='imagePreviewThemeParticipant' style={{backgroundColor: '#e6e6e6'}}/>
            )
        } else {
            imageThemeParticipantComponent = (
                <img src={"/files/file-upload.png"} id="imagePreviewThemeParticipant"
                     className='imagePreviewThemeParticipant' style={{backgroundColor: '#e6e6e6'}}/>
            )
        }

        //we will disable the tweets to show feature if the without moderator feature has been activated because in this case we show all the tweets 
        // to the participants without checking the approved field
        var tweetsToShowStatus;
        /* if (this.state.autoApproveTweets === "Without Moderator" || (this.state.autoApproveTweets === 'default' && this.props.event.autoApproveTweets === "Without Moderator")) {
            tweetsToShowStatus = "disabled";
        } else {
            tweetsToShowStatus = "";
        } */

        var addedClassAutoApproveTweets;
        if (this.props.event.autoApproveTweets === "Without Moderator") {
            addedClassAutoApproveTweets = " ";
        } else if (this.props.event.autoApproveTweets === "With Moderator") {
            addedClassAutoApproveTweets = " recent bg-primary ";

        }

        var addedClassTweetsToShow;
        if (this.state.tweetsToShow === "default" && this.props.event.tweetsToShow === "Approved") {
            addedClassTweetsToShow = " ";
        } else if (this.state.tweetsToShow === "default" && this.props.event.tweetsToShow === "Live") {
            addedClassTweetsToShow = " recent bg-primary";
        }

        var addedClassVoteResultsDisplay;
        if (this.state.voteResultsDisplay === "default" && this.props.event.voteResultsDisplay === "Percentage") {
            addedClassVoteResultsDisplay = " ";
        } else if (this.state.voteResultsDisplay === "default" && this.props.event.voteResultsDisplay === "Numbers") {
            addedClassVoteResultsDisplay = " recent bg-primary ";
        }

        var addedClassScreenNumber;
        if (this.state.screenNumber === "default" && this.props.event.screenNumber === "One") {
            addedClassScreenNumber = " ";
        } else if (this.state.screenNumber === "default" && this.props.event.screenNumber === "Two") {
            addedClassScreenNumber = " recent bg-primary ";

        }

        var addedClassOptionsOrderStatus;
        if (this.state.optionsOrderStatus === "default" && this.props.event.optionsOrderStatus === "Active") {
            addedClassOptionsOrderStatus = " recent bg-primary ";
        } else if (this.state.optionsOrderStatus === "default" && this.props.event.optionsOrderStatus === "Disable") {
            addedClassOptionsOrderStatus = " ";
        }

        var addedClassshowTweetsToParticipants;
        if (this.state.showTweetsToParticipants === "default" && this.props.event.showTweetsToParticipants === "Yes") {
            addedClassshowTweetsToParticipants = " recent bg-primary ";
        } else if (this.state.showTweetsToParticipants === "default" && this.props.event.showTweetsToParticipants === "No") {
            addedClassshowTweetsToParticipants = " ";
        }

        var addedClassShowEventInfoStatus;
        if (this.state.showEventInfoStatus === "default" && this.props.event.showEventInfoStatus === "Active") {
            addedClassShowEventInfoStatus = " recent bg-primary ";
        } else if (this.state.showEventInfoStatus === "default" && this.props.event.showEventInfoStatus === "Disable") {
            addedClassShowEventInfoStatus = " ";
        }

        var addedClassVoteCounterStatus;
        if (this.state.voteCounterStatus === "default" && this.props.event.voteCounterStatus === "Active") {
            addedClassVoteCounterStatus = " recent bg-primary ";
        } else if (this.state.voteCounterStatus === "default" && this.props.event.voteCounterStatus === "Disable") {
            addedClassVoteCounterStatus = " ";
        }

        var addedClassshowProfileToParticipants;
        if (this.state.showProfileToParticipants === "default" && this.props.event.showProfileToParticipants === "Yes") {
            addedClassshowProfileToParticipants = " recent bg-primary ";
        } else if (this.state.showProfileToParticipants === "default" && this.props.event.showProfileToParticipants === "No") {
            addedClassshowProfileToParticipants = " ";
        }

        var addedClassShowEmailToParticipants;
        if (this.state.showEmailToParticipants === "default" && this.props.event.showEmailToParticipants === "Yes") {
            addedClassShowEmailToParticipants = " recent bg-primary ";
        } else if (this.state.showEmailToParticipants === "default" && this.props.event.showEmailToParticipants === "No") {
            addedClassShowEmailToParticipants = " ";
        }

        var addedClassShowHeaderBar;
        if (this.state.showHeaderBar === "default" && this.props.event.showHeaderBar === "Active") {
            addedClassShowHeaderBar = " recent bg-primary ";
        } else if (this.state.showHeaderBar === "default" && this.props.event.showHeaderBar === "Disable") {
            addedClassShowHeaderBar = " ";
        }

        var addedClassAutomaticJumpPoll;
        if (this.state.automaticJumpPoll === "default" && this.props.event.automaticJumpPoll === "Active") {
            addedClassAutomaticJumpPoll = " recent bg-primary ";
        } else if (this.state.automaticJumpPoll === "default" && this.props.event.automaticJumpPoll === "Disable") {
            addedClassAutomaticJumpPoll = " ";
        }

        return (
            <form id='eventModalsForm'>
                <div className="modal fade " tabIndex="-1" role="dialog" id='editModal'>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{selectedLanguageStrings.edit_event}</h5>
                                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
                            </div>
                            <div className="modal-body">
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a className="nav-item nav-link active" id="nav-info-tab" data-toggle="tab"
                                           href="#nav-info" role="tab" aria-controls="nav-info"
                                           aria-selected="true">{selectedLanguageStrings.information}</a>
                                        <a className="nav-item nav-link" id="nav-menu-tab" data-toggle="tab"
                                           href="#nav-main-menu" role="tab" aria-controls="nav-main-menu"
                                           aria-selected="false">{selectedLanguageStrings.main_menu}</a>
                                        <a className="nav-item nav-link" id="nav-appearance-tab" data-toggle="tab"
                                           href="#nav-appearance" role="tab" aria-controls="nav-appearance"
                                           aria-selected="false">{selectedLanguageStrings.appearance}</a>
                                        <a className="nav-item nav-link" id="nav-custom-labels-tab" data-toggle="tab"
                                           href="#nav-custom-labels" role="tab" aria-controls="nav-custom-labels"
                                           aria-selected="false">{selectedLanguageStrings.custom_labels}</a>
                                        <a className="nav-item nav-link" id="nav-questions-tab" data-toggle="tab"
                                           href="#nav-questions" role="tab" aria-controls="nav-questions"
                                           aria-selected="false">{selectedLanguageStrings.questions}</a>
                                        <a className="nav-item nav-link" id="nav-tweets-tab" data-toggle="tab"
                                           href="#nav-tweets" role="tab" aria-controls="nav-tweets"
                                           aria-selected="false">{selectedLanguageStrings.tweets}</a>
                                        <a className="nav-item nav-link" id="nav-participants-tab" data-toggle="tab"
                                           href="#nav-participants" role="tab" aria-controls="nav-participants"
                                           aria-selected="false">{selectedLanguageStrings.participant_profile}</a>
                                        {/* <a className="nav-item nav-link" id="nav-survey-tab" data-toggle="tab" href="#nav-survey" role="tab" aria-controls="nav-tweets" aria-selected="false">{selectedLanguageStrings.survey}</a>
                                        <a className="nav-item nav-link" id="nav-survey-tab" data-toggle="tab" href="#nav-agenda" role="tab" aria-controls="nav-tweets" aria-selected="false">{selectedLanguageStrings.agenda}</a> */}
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active p-3" id="nav-info">
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputTitle">{selectedLanguageStrings.event_title}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-address-card"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text" className="form-control "
                                                           id={"inputTitle" + this.props.event.id}
                                                           placeholder="Event Title"
                                                           defaultValue={this.props.event.title} name="eventTitle"
                                                           onChange={this.handleEventChange} required={true}/>
                                                    <div className="invalid-feedback">
                                                        {selectedLanguageStrings.define_event_title}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="10"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(
                                                        <p className="text-left" style={{width: 300}}>The event code
                                                            will be used as the entrance password to your event in the
                                                            participant application.</p>
                                                    )}
                                                >
                                                    <label
                                                        htmlFor="inputCode">{selectedLanguageStrings.event_code}</label>
                                                </Tooltip>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fab fa-slack-hash"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                           id={"inputCode" + this.props.event.id}
                                                           placeholder="Event Code"
                                                           defaultValue={this.props.event.eventCode} name="eventCode"
                                                           onChange={this.handleEventChange}/>
                                                    <div className="invalid-feedback">
                                                        {selectedLanguageStrings.event_code_exists}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputStartingDate">{selectedLanguageStrings.starting_date}</label>
                                                <div className="input-group-prepend mb-3">
                                                    <div className="input-group-text"><i className="fas fa-calendar-alt"
                                                                                         id="dateIcon"></i></div>
                                                        {(this.state.startDateStatus === 'default') ? (
                                                            <div className="input-group-text" id="inputDate">
                                                                <DatePicker
                                                                    id="datepickerStart"
                                                                selected={objCurrEventStartDate}
                                                                    value={this.props.event.dtmStart}
                                                                    onChange={this.handleChange}
                                                                    calendarClassName="rasta-stripes"
                                                                    isClearable={true}
                                                                    minDate={moment()}
                                                                    placeholderText="Click to select a date"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="input-group-text" id="inputDate">
                                                                <DatePicker
                                                                    id="datepickerStart"
                                                                selected={moment(this.state.startDate, "MM/DD/YYYY")}
                                                                    value={this.state.startDate}
                                                                    onChange={this.handleChange}
                                                                    calendarClassName="rasta-stripes"
                                                                    isClearable={true}
                                                                    minDate={moment()}
                                                                    placeholderText="Click to select a date"
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputEndDate">{selectedLanguageStrings.ending_date}</label>
                                                <div className="input-group-prepend mb-3">
                                                    <div className="input-group-text">
                                                        <i className="fas fa-calendar-alt" id="dateIcon"></i>
                                                    </div>
                                                    {(this.state.endDateStatus === 'default') ? (
                                                        <div className="input-group-text" id="inputDate">
                                                            <DatePicker
                                                                id="datepickerEnd"
                                                                selected={objCurrEventEndDate}
                                                                value={this.props.event.dtmEnd}
                                                                onChange={this.handleEndChange}
                                                                calendarClassName="rasta-stripes"
                                                                minDate={moment(this.props.event.dtmStart, "MM/DD/YYYY")}
                                                                isClearable={true}
                                                                placeholderText="Click to select a date"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="input-group-text" id="inputDate">
                                                            <DatePicker
                                                                id="datepickerEnd"
                                                                selected={moment(this.state.endDate, "MM/DD/YYYY")}
                                                                value={this.state.endDate}
                                                                onChange={this.handleEndChange}
                                                                calendarClassName="rasta-stripes"
                                                                minDate={moment(this.state.startDate, "MM/DD/YYYY")}
                                                                isClearable={true}
                                                                placeholderText="Click to select a date"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label
                                                    htmlFor="availableLanguages">{selectedLanguageStrings.available_languages}</label>
                                            </div>
                                            <div className="col-auto">
                                                <div className="custom-control custom-checkbox mr-sm-2 text-left ml-1">
                                                    <input type="checkbox" className="custom-control-input"
                                                           id="eventLanguageItalian" name="eventLanguageItalian"
                                                           checked={this.state.availableLanguages.lang_it}
                                                           onChange={this.handleEventChange}></input>
                                                    <label className="custom-control-label form-check-label"
                                                           htmlFor="eventLanguageItalian">
                                                        <Flag code="it" className="flagImages"/>
                                                        {selectedLanguageStrings.italian} (default)
                                                    </label>

                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="custom-control custom-checkbox mr-sm-2 text-left ml-1">
                                                    <input type="checkbox" className="custom-control-input"
                                                           id="eventLanguageEnglish" name="eventLanguageEnglish"
                                                           checked={this.state.availableLanguages.lang_en}
                                                           onChange={this.handleEventChange}></input>
                                                    <label className="custom-control-label form-check-label"
                                                           htmlFor="eventLanguageEnglish">
                                                        <Flag code="gb" className="flagImages"/>
                                                        {selectedLanguageStrings.english}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`row align-items-center justify-content-center pt-4 ${this.state.eventCode === this.props.event.eventCode && this.props.event.id !== 0 ? '' : 'd-none'}`}>
                                                <div className="col-12 col-sm-12 col-md-3 col-lg-2 text-center mb-2">
                                                    <Tooltip
                                                        // options
                                                        position="top"
                                                        trigger={"mouseenter"}
                                                        distance="35"
                                                        animation={"fade"}
                                                        arrow={true}
                                                        duration={1200}
                                                        html={(
                                                            <p className="text-left" style={{ width: 270 }}>
                                                            This image includes your event code which can be used to
                                                            enter to your event page on the participant application.
                                                            </p>
                                                        )}
                                                    >
                                                    <QRCode value={"https://4meet.in/ec/" + this.state.eventCode}
                                                        id="qrCodeImage"
                                                        size="512" />
                                                    </Tooltip>
                                                </div>
                                            <div className="col-6 col-sm-5 col-md-4 col-lg-3 text-left p-0 ml-4 mb-2">
                                                <a id="downloadLink"
                                                   download={"4meet-Event-" + this.state.eventCode + ".jpg"}
                                                   href={this.state.hrefDownload}>
                                                        <Tooltip
                                                            // options
                                                            position="top"
                                                            trigger={"mouseenter"}
                                                            distance="10"
                                                            animation={"fade"}
                                                            arrow={true}
                                                            duration={1200}
                                                            html={(
                                                                <p className="text-left" style={{ width: 200 }}>
                                                                Download the image file of your event's QR code to
                                                                share with your participants.
                                                                </p>
                                                            )}
                                                        >
                                                        <button type="button" className="btn btn-primary p-1"
                                                                id="QrButtonDownload">
                                                                &nbsp;{selectedLanguageStrings.download_qr_code}&nbsp;
                                                            </button>
                                                        </Tooltip>
                                                    </a>
                                                </div>
                                            </div>
                                        <div
                                            className={`mt-4 ${this.state.eventCode === this.props.event.eventCode || this.props.event.id === 0 ? 'd-none' : ''}`}>
                                            Press <strong>Update</strong> to get the new QR Code
                                                </div>
                                    </div>
                                    <div className="tab-pane fade p-2" id="nav-main-menu">
                                        {/* MAIN MENU VISIBLE */}
                                        <div className="row mt-2">
                                            <div className="col-7 col-sm-7 col-md-6 col-lg-4">
                                                <label className="d-block" id="editTitleFont">
                                                    {selectedLanguageStrings.menu_visible}:
                                                </label>
                                            </div>
                                            <div className="col-2 col-sm-2 ">
                                                <button type="button" className="text-center tweetsToShow"
                                                    id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('visibleMainMenu')
                                                    }}>
                                                    <div
                                                        className={`switch ${this.state.mainMenu.visible ? 'recent bg-primary' : ''}`}>
                                                        <div className="textToggleOrder text-white ">
                                                            {this.state.mainMenu.visible ? selectedLanguageStrings.active : selectedLanguageStrings.disable}
                                                        </div>
                                                        <div className="inputOrder bg-white"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        {/* VOICE INTRO */}
                                        <div className="row mt-2">
                                            <div className="col-7 col-sm-7 col-md-6 col-lg-4">
                                                <label className="d-block" id="editTitleFont">
                                                    {selectedLanguageStrings.menu_intro}:
                                                </label>
                                            </div>
                                            <div className="col-2 col-sm-2 ">
                                                <button type="button" className="text-center tweetsToShow"
                                                        id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('introMainMenu')
                                                }}>
                                                    <div
                                                        className={`switch ${this.state.mainMenu.intro ? 'recent bg-primary' : ''}`}>
                                                        <div className="textToggleOrder text-white ">
                                                            {this.state.mainMenu.intro ? selectedLanguageStrings.active : selectedLanguageStrings.disable}
                                                        </div>
                                                        <div className="inputOrder bg-white"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        {/* VOICE HOME */}
                                        <div className="row mt-2">
                                            <div className="col-7 col-sm-7 col-md-6 col-lg-4">
                                                <label className="d-block" id="editTitleFont">
                                                    {selectedLanguageStrings.menu_home}:
                                                </label>
                                            </div>
                                            <div className="col-2 col-sm-2 ">
                                                <button type="button" className="text-center tweetsToShow"
                                                        id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('homeMainMenu')
                                                }}>
                                                    <div
                                                        className={`switch ${this.state.mainMenu.home ? 'recent bg-primary' : ''}`}>
                                                        <div className="textToggleOrder text-white ">
                                                            {this.state.mainMenu.home ? selectedLanguageStrings.active : selectedLanguageStrings.disable}
                                                        </div>
                                                        <div className="inputOrder bg-white"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        {/* VOICE QUESTIONS */}
                                        <div className="row mt-2">
                                            <div className="col-7 col-sm-7 col-md-6 col-lg-4">
                                                <label className="d-block" id="editTitleFont">
                                                    {selectedLanguageStrings.menu_questions}:
                                                </label>
                                            </div>
                                            <div className="col-2 col-sm-2 ">
                                                <button type="button" className="text-center tweetsToShow"
                                                    id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('questionsMainMenu')
                                                    }}>
                                                    <div
                                                        className={`switch ${this.state.mainMenu.questions ? 'recent bg-primary' : ''}`}>
                                                        <div className="textToggleOrder text-white ">
                                                            {this.state.mainMenu.questions ? selectedLanguageStrings.active : selectedLanguageStrings.disable}
                                                        </div>
                                                        <div className="inputOrder bg-white"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>                                        
                                        {/* VOICE LOGOUT */}
                                        <div className="row mt-2">
                                            <div className="col-7 col-sm-7 col-md-6 col-lg-4">
                                                <label className="d-block" id="editTitleFont">
                                                    {selectedLanguageStrings.menu_logout}:
                                                </label>
                                            </div>
                                            <div className="col-2 col-sm-2 ">
                                                <button type="button" className="text-center tweetsToShow"
                                                        id="removeBtnShadow" onClick={() => {
                                                    this.getUserPreferences('logoutMainMenu')
                                                }}>
                                                    <div
                                                        className={`switch ${this.state.mainMenu.logout ? 'recent bg-primary' : ''}`}>
                                                        <div className="textToggleOrder text-white ">
                                                            {this.state.mainMenu.logout ? selectedLanguageStrings.active : selectedLanguageStrings.disable}
                                                        </div>
                                                        <div className="inputOrder bg-white"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                                </div>
                                    <div className="tab-pane fade p-2" id="nav-appearance">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 text-center">
                                                {/* Logo */}
                                                <div> 
                                                    <Tooltip
                                                        position="top"
                                                        trigger={"mouseenter"}
                                                        distance="5"
                                                        animation={"fade"}
                                                        arrow={true}
                                                        duration={1200}
                                                        html={(<p className="text-left" style={{ width: 200 }}>
                                                            This image will be used as the general logo for your
                                                            event.</p>)}
                                                        >
                                                        <label className="editModalLabels" htmlFor="inputLogo">
                                                            {selectedLanguageStrings.logo}
                                                        </label>
                                                    </Tooltip>
                                                </div>
                                               <div className="d-flex justify-content-center mb-2">
                                                    <div className="logoBox d-flex text-center">
                                                        {(this.state.logoStatus !== 'NoImageSelected' || (this.props.event && this.props.event.eventLogoUrl !== "default" && this.state.logoPreviewStatus === "default")) ? (
                                                            <div className="closeButtonImage" onClick={() => {
                                                                this.removePhoto('LOGO')
                                                            }}>
                                                                <i className="fas fa-window-close closeImageIcon"></i>
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        <Dropzone
                                                            className="inputLogoEditModal d-flex text-center"
                                                            onDrop={this.handleDropLogoImage}>
                                                            <div className="text-center p-0 my-auto" >
                                                                {(this.props.event && this.props.event.eventLogoUrl !== "default" && this.state.logoPreviewStatus === "default") ? (
                                                                    <img src={this.props.event.eventLogoUrl}
                                                                         id="imagePreview"
                                                                         className='logoEditModalImage'
                                                                         style={{backgroundColor: '#e6e6e6'}}/>
                                                                ) : (
                                                                    <div>
                                                                        {logoPreviewComponent}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Dropzone>
                                                    </div>
                                                    <input type="file" accept="image/png, image/jpeg, image/gif"
                                                           className="form-control" id="inputLogo" hidden={true}
                                                           placeholder="Logo" name="LogoImage"
                                                           onChange={this.handleUploadImageChange}/>
                                                </div>

                                                {/* Intro page */}
                                                <div>
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                        html={(<p className="text-left" style={{width: 270}}>This image
                                                            will be used for the intro page in the participant
                                                            application to present your event.</p>)}
                                                    >
                                                        <label className="editModalLabels"
                                                            htmlFor="inputSplash">{selectedLanguageStrings.intro_page}</label>
                                                </Tooltip>
                                                </div>
                                                <div className="d-flex justify-content-center mb-2">
                                                    <div className="splashBox d-flex text-center">
                                                        {(this.state.splashStatus !== 'NoImageSelected' || (this.props.event && this.props.event.splashImageUrl !== "default" && this.state.splashScreenPreviewStatus === "default")) ? (
                                                            <div className="closeButtonImage" onClick={() => {
                                                                this.removePhoto('SPLASH')
                                                            }}>
                                                                <i className="fas fa-window-close closeImageIcon"></i>
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        <Dropzone
                                                            className="inputSplashEditModal d-flex text-center"
                                                            onDrop={this.handleDropSplashImage}>
                                                            <div className="text-center p-0 my-auto" >
                                                                {(this.props.event && this.props.event.splashImageUrl !== "default" && this.state.splashScreenPreviewStatus === "default") ? (
                                                                    <img src={this.props.event.splashImageUrl}
                                                                         id="logoPreviewSplash"
                                                                         className='splashEditModalImage'
                                                                         style={{backgroundColor: '#e6e6e6'}}/>
                                                                ) : (
                                                                    <div>
                                                                        {imageSplashPreviewComponent}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Dropzone>
                                                    </div>
                                                    <input type="file" accept="image/png, image/jpeg, image/gif"
                                                           className="form-control" id="inputSplash" hidden={true}
                                                        placeholder="Splash Page" name="SplashPage"
                                                        onChange={this.handleUploadImageChange} />
                                                </div>

                                                {/* Home page */}
                                                <div>
                                                    <Tooltip
                                                        // options
                                                        position="top"
                                                        trigger={"mouseenter"}
                                                        distance="5"
                                                        animation={"fade"}
                                                        arrow={true}
                                                        duration={1200}
                                                        html={(<p className="text-left" style={{ width: 270 }}>This image
                                                            will be used to show your logo in the home/room page in the participant
                                                            application.</p>)}
                                                    >
                                                        <label className="editModalLabels"
                                                            htmlFor="inputHome">{selectedLanguageStrings.home_page}</label>
                                                    </Tooltip>
                                                </div>
                                                <div className="d-flex justify-content-center mb-2">
                                                    <div className="splashBox d-flex text-center">
                                                        {(this.state.homeStatus !== 'NoImageSelected' || (this.props.event && this.props.event.homeImageUrl !== "default" && this.state.homeScreenPreviewStatus === "default")) ? (
                                                            <div className="closeButtonImage" onClick={() => {
                                                                this.removePhoto('HOME')
                                                            }}>
                                                                <i className="fas fa-window-close closeImageIcon"></i>
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        <Dropzone
                                                            className="inputHomeEditModal d-flex text-center"
                                                            onDrop={this.handleDropHomeImage}>
                                                            <div className="text-center p-0 my-auto">
                                                                {(this.props.event && this.props.event.homeImageUrl !== "default" && this.state.homeScreenPreviewStatus === "default") ? (
                                                                    <img src={this.props.event.homeImageUrl}
                                                                        id="logoPreviewHome"
                                                                        className='homeEditModalImage'
                                                                        style={{ backgroundColor: '#e6e6e6' }} />
                                                                ) : (
                                                                    <div>
                                                                        {imageHomePreviewComponent}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Dropzone>
                                                    </div>
                                                    <input type="file" accept="image/png, image/jpeg, image/gif"
                                                        className="form-control" id="inputHome" hidden={true}
                                                        placeholder="Home Page" name="HomePage"
                                                           onChange={this.handleUploadImageChange}/>
                                                </div>

                                                {/* Theme Participant app*/}
                                                <div>
                                                    <Tooltip
                                                        position="top"
                                                        trigger={"mouseenter"}
                                                        distance="5"
                                                        animation={"fade"}
                                                        arrow={true}
                                                        duration={1200}
                                                        html={(
                                                        <p className="text-left" style={{width: 370}}>
                                                                This image will be used as the background theme in the
                                                                participant application.
                                                                <br/>
                                                                <span>
                                                                    - For phones - screens max-width: 576px
                                                                    <br/>- For tablets - screens max-width: 768px
                                                                    <br/>- For small laptops - screens max-width: 992px
                                                                    <br/>- For laptops & desktops - screens max-width:
                                                                        1200px
                                                                </span>
                                                        </p>
                                                        )}
                                                    >
                                                        <label className="editModalLabels"
                                                               htmlFor="inputThemeParticipant">{selectedLanguageStrings.theme_participant}</label>
                                                    </Tooltip>
                                                </div>
                                                <div className="d-flex justify-content-center mb-2">
                                                    <div className="splashBox d-flex text-center">
                                                        {(this.state.themeParticipantStatus !== 'NoImageSelected' || (this.props.event && this.props.event.themeParticipantImageUrl !== "default" && this.state.themeParticipantPreviewStatus === "default")) ? (
                                                            <div className="closeButtonImage" onClick={() => {
                                                                this.removePhoto('ThemeParticipant')
                                                            }}>
                                                                <i className="fas fa-window-close closeImageIcon"></i>
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        <Dropzone
                                                            className="d-flex text-center"
                                                            onDrop={this.handleDropThemeParticipant}>
                                                            <div className="text-center p-0 my-auto">
                                                                {(this.props.event && this.props.event.themeParticipantImageUrl !== "default" && this.state.themeParticipantPreviewStatus === "default") ? (
                                                                    <img src={this.props.event.themeParticipantImageUrl}
                                                                         id="imagePreviewThemeParticipant"
                                                                         className='imagePreviewThemeParticipant'
                                                                         style={{backgroundColor: '#e6e6e6'}}/>
                                                                ) : (
                                                                    <div>
                                                                        {imageThemeParticipantComponent}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Dropzone>
                                                    </div>
                                                    <input type="file" accept="image/png, image/jpeg, image/gif"
                                                           className="form-control" id="inputThemeParticipant"
                                                           hidden={true} placeholder="Theme Participant"
                                                           name="ThemeParticipant"
                                                           onChange={this.handleUploadImageChange}/>
                                                </div>

                                                {/* Theme Big Screen */}
                                                <div>
                                                    <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(<p className="text-left" style={{ width: 270 }}>
                                                            This image will be used as the background theme in the Big
                                                            screen view.</p>)}
                                                    >
                                                        <label className="editModalLabels"
                                                               htmlFor="inputThemeBigScreen">
                                                        {selectedLanguageStrings.theme_big_screen}
                                                        </label>
                                                    </Tooltip>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="splashBox d-flex text-center">
                                                        {(this.state.themebigScreenStatus !== 'NoImageSelected' || (this.props.event && this.props.event.themeBigScreenImageUrl !== "default" && this.state.themebigScreenPreviewStatus === "default")) ? (
                                                            <div className="closeButtonImage" onClick={() => {
                                                                this.removePhoto('ThemeBigScreen')
                                                            }}>
                                                                <i className="fas fa-window-close closeImageIcon"></i>
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        <Dropzone
                                                            className="d-flex text-center"
                                                            onDrop={this.handleDropThemeBigScreen}>
                                                            <div className="text-center p-0 my-auto" >
                                                                {(this.props.event && this.props.event.themeBigScreenImageUrl !== "default" && this.state.themebigScreenPreviewStatus === "default") ? (
                                                                    <img src={this.props.event.themeBigScreenImageUrl}
                                                                         id="imagePreviewThemeBigScreen"
                                                                         className='imagePreviewThemeBigScreen'
                                                                         style={{backgroundColor: '#e6e6e6'}}/>
                                                                ) : (
                                                                    <div>
                                                                        {imageThemeBigScreenComponent}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Dropzone>
                                                    </div>
                                                    <input type="file" accept="image/png, image/jpeg, image/gif"
                                                           className="form-control" id="inputThemeBigScreen"
                                                           hidden={true} placeholder="Theme Big Screen"
                                                           name="ThemeBigScreen"
                                                           onChange={this.handleUploadImageChange}/>
                                                </div>
                                                
                                            </div>
                                         
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-8 mt-1">
                                                <div className="row mt-2">
                                                    <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                                                        <Tooltip
                                                            // options
                                                            position="top"
                                                            trigger={"mouseenter"}
                                                            distance="5"
                                                            animation={"fade"}
                                                            arrow={true}
                                                            duration={1200}
                                                            html={(
                                                                <p className="text-left" style={{ width: 250 }}>
                                                                    Showing the title and event code on the header of the
                                                                    participant's app.</p>
                                                            )}
                                                        >
                                                            <label className="editModalLabels d-block"
                                                                   id="editTitleFont">
                                                                {selectedLanguageStrings.show_event_info}
                                                            </label>
                                                        </Tooltip>
                                                    </div>

                                                    <div className="col-2">
                                                        {(this.state.showEventInfoStatus === 'default') ? (
                                                            <button type="button" className="text-center tweetsToShow"
                                                                    id="removeBtnShadow" onClick={() => {
                                                                this.getUserPreferences('showEventInfoStatus')
                                                            }}>
                                                                <div
                                                                    className={"switch " + addedClassShowEventInfoStatus}>
                                                                    {(this.props.event.showEventInfoStatus === "Active") ? (
                                                                        <div className="textToggleOrder text-white"
                                                                             id="showEventInfoStatus">{selectedLanguageStrings.active}</div>
                                                                    ) : (
                                                                        <div className="textToggleOrder text-white"
                                                                             id="showEventInfoStatus">{selectedLanguageStrings.disable}</div>
                                                                        )}
                                                                    <div className="inputOrder bg-white"></div>
                                                                </div>
                                                            </button>
                                                        ) : (
                                                            <button type="button" className="text-center tweetsToShow"
                                                                    id="removeBtnShadow" onClick={() => {
                                                                this.getUserPreferences('showEventInfoStatus')
                                                            }}>
                                                                <div
                                                                    className={"switch " + this.state.addedClassShowEventInfoStatus}>
                                                                        {(this.state.showEventInfoStatus === "Active") ? (
                                                                        <div className="textToggleOrder text-white"
                                                                             id="showEventInfoStatus">{selectedLanguageStrings.active}</div>
                                                                        ) : (
                                                                        <div className="textToggleOrder text-white"
                                                                             id="showEventInfoStatus">{selectedLanguageStrings.disable}</div>
                                                                            )}
                                                                        <div className="inputOrder bg-white"></div>
                                                                    </div>
                                                                </button>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                                                        <Tooltip
                                                            // options
                                                            position="top"
                                                            trigger={"mouseenter"}
                                                            distance="5"
                                                            animation={"fade"}
                                                            arrow={true}
                                                            duration={1200}
                                                            html={(
                                                                <p className="text-left" style={{ width: 250 }}>Show/Hide Header Bar</p>
                                                            )}
                                                        >
                                                            <label className="editModalLabels d-block"
                                                                id="editTitleFont">
                                                                {selectedLanguageStrings.show_header_bar}
                                                            </label>
                                                        </Tooltip>
                                                    </div>

                                                    <div className="col-2">
                                                        {(this.state.showHeaderBar === 'default') ? (
                                                            <button type="button" className="text-center tweetsToShow"
                                                                id="removeBtnShadow" onClick={() => {
                                                                    this.getUserPreferences('showHeaderBar')
                                                                }}>
                                                                <div
                                                                    className={"switch " + addedClassShowHeaderBar}>
                                                                    {(this.props.event.showHeaderBar === "Active") ? (
                                                                        <div className="textToggleOrder text-white"
                                                                            id="showHeaderBar">{selectedLanguageStrings.active}</div>
                                                                    ) : (
                                                                        <div className="textToggleOrder text-white"
                                                                            id="showHeaderBar">{selectedLanguageStrings.disable}</div>
                                                                    )}
                                                                    <div className="inputOrder bg-white"></div>
                                                                </div>
                                                            </button>
                                                        ) : (
                                                            <button type="button" className="text-center tweetsToShow"
                                                                id="removeBtnShadow" onClick={() => {
                                                                    this.getUserPreferences('showHeaderBar')
                                                                }}>
                                                                <div
                                                                    className={"switch " + this.state.addedClassShowHeaderBar}>
                                                                    {(this.state.showHeaderBar === "Active") ? (
                                                                        <div className="textToggleOrder text-white"
                                                                            id="showHeaderBar">{selectedLanguageStrings.active}</div>
                                                                    ) : (
                                                                        <div className="textToggleOrder text-white"
                                                                            id="showHeaderBar">{selectedLanguageStrings.disable}</div>
                                                                    )}
                                                                    <div className="inputOrder bg-white"></div>
                                                                </div>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <label
                                                            className="editModalLabels">{selectedLanguageStrings.choose_language}</label>
                                                    </div>
                                                </div>

                                                {(this.state.selectedLanguage === "default") ? (
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="englishLanguage" id="englishLanguage"
                                                                       value="english" onChange={() => {
                                                                    this.getUserPreferences('selectedLanguage')
                                                                }} checked={selectedLanguageStateEnglish}/>
                                                                <label className="form-check-label"
                                                                       htmlFor="englishLanguage">
                                                                    <Flag code="gb" className="flagImages" />
                                                                    {selectedLanguageStrings.english}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-5">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="italianLanguage" id="italianLanguage"
                                                                       value="italian" onChange={() => {
                                                                    this.getUserPreferences('selectedLanguage')
                                                                }} checked={selectedLanguageStateItalian}/>
                                                                <Flag code="it" className="flagImages" />
                                                                <label className="form-check-label"
                                                                       htmlFor="italianLanguage">
                                                                    {selectedLanguageStrings.italian}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="englishLanguage" id="englishLanguage"
                                                                       value="english" onClick={() => {
                                                                    this.getUserPreferences('selectedLanguage')
                                                                }} checked={this.state.englishChecked}/>

                                                                <label className="form-check-label"
                                                                       htmlFor="englishLanguage">
                                                                        <Flag code="gb" className="flagImages" />
                                                                        {selectedLanguageStrings.english}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-5">
                                                                <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="italianLanguage" id="italianLanguage"
                                                                       value="italian" onClick={() => {
                                                                    this.getUserPreferences('selectedLanguage')
                                                                }} checked={this.state.italianChecked}/>
                                                                    <Flag code="it" className="flagImages" />
                                                                <label className="form-check-label"
                                                                       htmlFor="italianLanguage">
                                                                        {selectedLanguageStrings.italian}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                <div className="row mt-4">
                                                    <div className="col-6">
                                                        <Tooltip
                                                            // options
                                                            position="top"
                                                            trigger={"mouseenter"}
                                                            distance="5"
                                                            animation={"fade"}
                                                            animateFill={false}
                                                            duration={1200}
                                                            html={(
                                                                <p className="text-left" style={{width: 250}}>This color
                                                                    will be used for the headers (Navigation Bar) on the
                                                                    big screen and also the participant application.</p>
                                                            )}
                                                        >
                                                            <label
                                                                className="editModalLabels d-block">{selectedLanguageStrings.header_background_color}</label>
                                                        </Tooltip>
                                                        {(this.state.headerBackgroundColorStatus === 'default') ? (
                                                            <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="headerBackgroundColor"
                                                                       value={this.props.event.headerBackgroundColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                <p className="color-code pl-2">{this.props.event.headerBackgroundColor}</p>
                                                            </div>
                                                        ) : (
                                                                <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="headerBackgroundColor"
                                                                       value={this.state.headerBackgroundColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                    <p className="color-code pl-2">{this.state.headerBackgroundColor}</p>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="col-6">
                                                        <Tooltip
                                                            // options
                                                            position="top"
                                                            trigger={"mouseenter"}
                                                            distance="5"
                                                            animation={"fade"}
                                                            arrow={true}
                                                            duration={1200}
                                                            html={(
                                                                <p className="text-left" style={{width: 290}}>This color
                                                                    will be used for the texts inside the headers
                                                                    (Navigation Bar) on the big screen and also the
                                                                    participant application.</p>
                                                            )}
                                                        >
                                                            <label htmlFor="inputSecondaryColor"
                                                                   className="editModalLabels d-block">{selectedLanguageStrings.header_text_color}</label>
                                                        </Tooltip>
                                                        {(this.state.headerTextColorStatus === 'default') ? (
                                                            <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="headerTextColor"
                                                                       value={this.props.event.headerTextColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                <p className="color-code pl-2">{this.props.event.headerTextColor}</p>
                                                            </div>
                                                        ) : (
                                                                <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="headerTextColor"
                                                                       value={this.state.headerTextColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                    <p className="color-code pl-2">{this.state.headerTextColor}</p>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-6">
                                                        <Tooltip
                                                            // options
                                                            position="top"
                                                            trigger={"mouseenter"}
                                                            distance="5"
                                                            animation={"fade"}
                                                            animateFill={false}
                                                            duration={1200}
                                                            html={(
                                                                <p className="text-left" style={{width: 250}}>This color
                                                                    will be used for the headers on the big screen and
                                                                    also the participant application.</p>
                                                            )}
                                                        >
                                                            <label
                                                                className="editModalLabels d-block">{selectedLanguageStrings.background_color}</label>
                                                        </Tooltip>
                                                        {(this.state.primaryColorStatus === 'default') ? (
                                                            <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="inputPrimaryColor"
                                                                       value={this.props.event.primaryColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                <p className="color-code pl-2">{this.props.event.primaryColor}</p>
                                                            </div>
                                                        ) : (
                                                                <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="inputPrimaryColor"
                                                                       value={this.state.primaryColor} onChange={() => {
                                                                    this.handleChangeColor()
                                                                }}/>
                                                                    <p className="color-code pl-2">{this.state.primaryColor}</p>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="col-6">
                                                        <Tooltip
                                                            // options
                                                            position="top"
                                                            trigger={"mouseenter"}
                                                            distance="5"
                                                            animation={"fade"}
                                                            arrow={true}
                                                            duration={1200}
                                                            html={(
                                                                <p className="text-left" style={{width: 290}}>This color
                                                                    will be used for the texts inside the headers on the
                                                                    big screen and also the participant application.</p>
                                                            )}
                                                        >
                                                            <label htmlFor="inputSecondaryColor"
                                                                   className="editModalLabels d-block">{selectedLanguageStrings.text_color}</label>
                                                        </Tooltip>
                                                        {(this.state.textColorStatus === 'default') ? (
                                                            <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="inputTextColor"
                                                                       value={this.props.event.textColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                <p className="color-code pl-2">{this.props.event.textColor}</p>
                                                            </div>
                                                        ) : (
                                                                <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="inputTextColor" value={this.state.textColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                    <p className="color-code pl-2">{this.state.textColor}</p>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-6">
                                                        <Tooltip
                                                            // options
                                                            position="top"
                                                            trigger={"mouseenter"}
                                                            distance="5"
                                                            animation={"fade"}
                                                            arrow={true}
                                                            duration={1200}
                                                            html={(
                                                                <p className="text-left" style={{width: 250}}>This color
                                                                    will be used as the background color for the content
                                                                    to be shown on the big screen.</p>
                                                            )}
                                                        >
                                                            <label
                                                                className="editModalLabels d-block">{selectedLanguageStrings.background_color_theme}</label>
                                                        </Tooltip>
                                                        {(this.state.backgroundColorStatus === 'default') ? (
                                                            <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="inputBackgroundColor"
                                                                       value={this.props.event.backgroundColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                <p className="color-code pl-2">{this.props.event.backgroundColor}</p>
                                                            </div>
                                                        ) : (
                                                                <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="inputBackgroundColor"
                                                                       value={this.state.backgroundColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                    <p className="color-code pl-2">{this.state.backgroundColor}</p>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="col-6 ">
                                                        <Tooltip
                                                            // options
                                                            position="top"
                                                            trigger={"mouseenter"}
                                                            distance="5"
                                                            animation={"fade"}
                                                            arrow={true}
                                                            duration={1200}
                                                            html={(
                                                                <p className="text-left" style={{width: 250}}>This color
                                                                    will be used as the text color of the content to be
                                                                    shown on the big screen.</p>
                                                            )}
                                                        >
                                                            <label htmlFor="inputSecondaryColor"
                                                                   className="editModalLabels d-block">{selectedLanguageStrings.text_color_theme}</label>
                                                        </Tooltip>
                                                        {(this.state.backgroundTextColorStatus === 'default') ? (
                                                            <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="inputBackgroundTextColor"
                                                                       value={this.props.event.backgroundTextColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                <p className="color-code pl-2">{this.props.event.backgroundTextColor}</p>
                                                            </div>
                                                        ) : (
                                                                <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="inputBackgroundTextColor"
                                                                       value={this.state.backgroundTextColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                    <p className="color-code pl-2">{this.state.backgroundTextColor}</p>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-8">
                                                        <Tooltip
                                                            // options
                                                            position="top"
                                                            trigger={"mouseenter"}
                                                            distance="5"
                                                            animation={"fade"}
                                                            arrow={true}
                                                            duration={1200}
                                                            html={(
                                                                <p className="text-left" style={{ width: 280 }}>
                                                                    This color will be used for the progress bar of
                                                                    received votes to differentiate
                                                                    the correct answer among the other existing choices.
                                                                </p>
                                                            )}
                                                        >
                                                            <label htmlFor="inputCorrectQuestionColor"
                                                                   className="editModalLabels d-block">{selectedLanguageStrings.correct_question_color}</label>
                                                        </Tooltip>
                                                        {(this.state.correctQuestionColorStatus === 'default') ? (
                                                            <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="inputCorrectQuestionColor"
                                                                       value={this.props.event.correctQuestionColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                <p className="color-code pl-2">{this.props.event.correctQuestionColor}</p>
                                                            </div>
                                                        ) : (
                                                                <div className="input-group">
                                                                <input type="color" className="form-control-color"
                                                                       id="inputCorrectQuestionColor"
                                                                       value={this.state.correctQuestionColor}
                                                                       onChange={() => {
                                                                           this.handleChangeColor()
                                                                       }}/>
                                                                    <p className="color-code pl-2">{this.state.correctQuestionColor}</p>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            <p id="imageSelectNote">
                                                {selectedLanguageStrings.upload_note}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade p-3" id="nav-custom-labels">
                                        <div className="row">
                                            <div className="col-12">
                                                <label
                                                    htmlFor="inputLanguage">{selectedLanguageStrings.select_language}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-language"></i>
                                                        </div>
                                                    </div>
                                                    <select id="inputLanguage" className="form-control"
                                                            onChange={this.handleCustomLabelsLanguageChange}>
                                                        <option
                                                            value="lang_it">{selectedLanguageStrings.italian}</option>
                                                        <option
                                                            value="lang_en">{selectedLanguageStrings.english}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <hr/>
                                                <label
                                                    htmlFor="inputQuestion"
                                                    className="font-weight-bold">{selectedLanguageStrings.category_questions}</label>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputLiveQuestions">{selectedLanguageStrings.client_live_questions}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-tags"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                           id={"inputLiveQuestions"}
                                                           placeholder={selectedLanguageStrings.client_live_questions}
                                                           value={this.state.customLabels[this.state.customLabelsLanguage].live_questions}
                                                           name="customLabel-live_questions"
                                                           onChange={this.handleEventChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputYourQuestions">{selectedLanguageStrings.client_your_questions}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-tags"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                           id={"inputYourQuestions"}
                                                           placeholder={selectedLanguageStrings.client_your_questions}
                                                           value={this.state.customLabels[this.state.customLabelsLanguage].your_questions}
                                                           name="customLabel-your_questions"
                                                           onChange={this.handleEventChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputYouDidNotSendAnyQuestionYet">{selectedLanguageStrings.client_you_did_not_send_any_question_yet}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-tags"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                           id={"inputYouDidNotSendAnyQuestionYet"}
                                                           placeholder={selectedLanguageStrings.client_you_did_not_send_any_question_yet}
                                                           value={this.state.customLabels[this.state.customLabelsLanguage].you_did_not_send_any_question_yet}
                                                           name="customLabel-you_did_not_send_any_question_yet"
                                                           onChange={this.handleEventChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputWriteANewQuestion">{selectedLanguageStrings.client_write_a_new_question}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-tags"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                           id={"inputWriteANewQuestion"}
                                                           placeholder={selectedLanguageStrings.client_write_a_new_question}
                                                           value={this.state.customLabels[this.state.customLabelsLanguage].write_a_new_question}
                                                           name="customLabel-write_a_new_question"
                                                           onChange={this.handleEventChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputYourQuestion">{selectedLanguageStrings.client_your_question}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-tags"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                           id={"inputYourQuestion"}
                                                           placeholder={selectedLanguageStrings.client_your_question}
                                                           value={this.state.customLabels[this.state.customLabelsLanguage].your_question}
                                                           name="customLabel-your_question"
                                                           onChange={this.handleEventChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <hr/>
                                                <label
                                                    htmlFor="inputQuestion"
                                                    className="font-weight-bold">{selectedLanguageStrings.category_polls}</label>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputClientLivePoll">{selectedLanguageStrings.client_live_poll}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-tags"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                           id={"inputClientLivePoll"}
                                                           placeholder={selectedLanguageStrings.client_live_poll}
                                                           value={this.state.customLabels[this.state.customLabelsLanguage].live_poll}
                                                           name="customLabel-live_poll"
                                                           onChange={this.handleEventChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputSendAnswer">{selectedLanguageStrings.client_send_answer}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-tags"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                           id={"inputSendAnswer"}
                                                           placeholder={selectedLanguageStrings.client_send_answer}
                                                           value={this.state.customLabels[this.state.customLabelsLanguage].send_answer}
                                                           name="customLabel-send_answer"
                                                           onChange={this.handleEventChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <hr/>
                                                <label
                                                    htmlFor="inputQuestion"
                                                    className="font-weight-bold">{selectedLanguageStrings.category_main_menu}</label>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputClientSelectLanguage">{selectedLanguageStrings.client_select_language}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-tags"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                           id={"inputClientSelectLanguage"}
                                                           placeholder={selectedLanguageStrings.client_select_language}
                                                           value={this.state.customLabels[this.state.customLabelsLanguage].select_language}
                                                           name="customLabel-select_language"
                                                           onChange={this.handleEventChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputClientSelectRoom">{selectedLanguageStrings.client_select_room}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-tags"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                           id={"inputClientSelectRoom"}
                                                           placeholder={selectedLanguageStrings.client_select_room}
                                                           value={this.state.customLabels[this.state.customLabelsLanguage].select_room}
                                                           name="customLabel-select_room"
                                                           onChange={this.handleEventChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputClientQuestions">{selectedLanguageStrings.client_questions}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-tags"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                        id={"inputClientQuestions"}
                                                        placeholder={selectedLanguageStrings.client_questions}
                                                        value={this.state.customLabels[this.state.customLabelsLanguage].questions}
                                                        name="customLabel-questions"
                                                           onChange={this.handleEventChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label
                                                    htmlFor="inputClientLogout">{selectedLanguageStrings.client_logout}</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fas fa-tags"></i>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                           className={"form-control " + this.state.inputClass}
                                                           id={"inputClientLogout"}
                                                           placeholder={selectedLanguageStrings.client_logout}
                                                           value={this.state.customLabels[this.state.customLabelsLanguage].logout}
                                                           name="customLabel-logout"
                                                           onChange={this.handleEventChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade p-3" id="nav-questions">
                                        <div className="row mt-2">
                                            <div className="col-7 col-sm-7 col-md-6 col-lg-4">
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(
                                                        <p className="text-left" style={{width: 250}}>Showing the number
                                                            of votes received from the participants on the big screen in
                                                            a live question session.</p>
                                                    )}
                                                >
                                                    <label className="d-block" id="editTitleFont">
                                                        {selectedLanguageStrings.show_vote_counter}
                                                    </label>
                                                </Tooltip>
                                            </div>
                                            <div className="col-2 col-sm-2 ">
                                                {(this.state.voteCounterStatus === 'default') ? (
                                                    <button type="button" className="text-center tweetsToShow "
                                                            id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('VoteCounterStatus')
                                                    }}>
                                                        <div className={"switch " + addedClassVoteCounterStatus} >
                                                            {(this.props.event.voteCounterStatus === "Active") ? (
                                                                <div className="textToggleOrder text-white ">
                                                                    {selectedLanguageStrings.active}
                                                                </div>
                                                            ) : (
                                                                <div className="textToggleOrder text-white ">
                                                                    {selectedLanguageStrings.disable}
                                                                </div>
                                                            )}
                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button type="button" className="text-center tweetsToShow "
                                                            id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('VoteCounterStatus')
                                                    }}>
                                                        <div
                                                            className={"switch " + this.state.addedClassVoteCounterStatus}>
                                                                {(this.state.voteCounterStatus === "Active") ? (
                                                                    <div className="textToggleOrder text-white">
                                                                        {selectedLanguageStrings.active}
                                                                    </div>
                                                                ) : (
                                                                    <div className="textToggleOrder text-white">
                                                                        {selectedLanguageStrings.disable}
                                                                    </div>
                                                                )}
                                                                <div className="inputOrder bg-white"></div>
                                                            </div>
                                                        </button>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-7 col-sm-7 col-md-6 col-lg-4">
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(
                                                        <p className="text-left" style={{ width: 300 }}>
                                                            You can order the options of a question based on the number
                                                            of votes they have received.
                                                        </p>
                                                    )}
                                                >
                                                    <label className="d-block" id="editTitleFont">
                                                        {selectedLanguageStrings.order_question_ptions}
                                                    </label>
                                                </Tooltip>
                                            </div>
                                            <div className="col-2">
                                                {(this.state.optionsOrderStatus === 'default') ? (
                                                    <button type="button" className="text-center tweetsToShow "
                                                            id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('OptionsOrderStatus')
                                                    }}>
                                                        <div className={"switch " + addedClassOptionsOrderStatus} >
                                                            {(this.props.event.optionsOrderStatus === "Active") ? (
                                                                <div
                                                                    className="textToggleOrder text-white ">{selectedLanguageStrings.active}</div>
                                                            ) : (
                                                                <div
                                                                    className="textToggleOrder text-white ">{selectedLanguageStrings.disable}</div>
                                                                )}

                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button type="button" className="text-center tweetsToShow "
                                                            id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('OptionsOrderStatus')
                                                    }}>
                                                        <div
                                                            className={"switch " + this.state.addedClassOptionsOrderStatus}>
                                                                {(this.state.optionsOrderStatus === "Active") ? (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.active}</div>
                                                                ) : (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.disable}</div>
                                                                    )}
                                                                <div className="inputOrder bg-white"></div>
                                                            </div>
                                                        </button>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-7 col-sm-7 col-md-6 col-lg-4">
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(
                                                        <p className="text-left" style={{width: 250}}>You can have the
                                                            simultaneous big screen view for the live question and live
                                                            tweets seperately or one of them at a time.</p>
                                                    )}
                                                >
                                                    <label className="d-block"
                                                           id="editTitleFont">{selectedLanguageStrings.number_live_screens}</label>
                                                </Tooltip>
                                            </div>
                                            <div className="col-2">
                                                {(this.state.screenNumber === 'default') ? (
                                                    <button type="button" className="text-center tweetsToShow"
                                                            id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('ScreenNumber')
                                                    }}>
                                                        <div className={"switch " + addedClassScreenNumber} >
                                                            {(this.props.event.screenNumber === "One") ? (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.one}</div>
                                                            ) : (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.two}</div>
                                                                )}
                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button type="button" className="text-center tweetsToShow"
                                                            id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('ScreenNumber')
                                                    }}>
                                                            <div className={"switch " + this.state.addedClassScreenNumber} >
                                                                {(this.state.screenNumber === "One") ? (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.one}</div>
                                                                ) : (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.two}</div>
                                                                    )}
                                                                <div className="inputOrder bg-white"></div>
                                                            </div>
                                                        </button>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-7 col-sm-7 col-md-6 col-lg-4">
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(
                                                        <p className="text-left" style={{width: 250}}>Showing the
                                                            received votes as numbers or converting them to the
                                                            percentage values.</p>
                                                    )}
                                                >
                                                    <label className="d-block"
                                                           id="editTitleFont">{selectedLanguageStrings.display_vote_results}</label>
                                                </Tooltip>
                                            </div>
                                            <div className="col-2">
                                                {(this.state.voteResultsDisplay === 'default') ? (
                                                    <button type="button" className="text-center tweetsToShow"
                                                            id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('VoteResultsDisplay')
                                                    }}>
                                                        <div className={"switch " + addedClassVoteResultsDisplay} >
                                                            {(this.props.event.voteResultsDisplay === "Percentage") ? (
                                                                <div className="textToggleOrder text-white"
                                                                     id="voteResultsDisplay">{selectedLanguageStrings.percentage}</div>
                                                            ) : (
                                                                <div className="textToggleOrder text-white"
                                                                     id="voteResultsDisplay">{selectedLanguageStrings.numbers}</div>
                                                                )}
                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button type="button" className="text-center tweetsToShow"
                                                            id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('VoteResultsDisplay')
                                                    }}>
                                                        <div
                                                            className={"switch " + this.state.addedClassVoteResultsDisplay}>
                                                            {(this.state.voteResultsDisplay === "Percentage") ? (
                                                                <div className="textToggleOrder text-white"
                                                                     id="voteResultsDisplay">{selectedLanguageStrings.percentage}</div>
                                                            ) : (
                                                                <div className="textToggleOrder text-white"
                                                                     id="voteResultsDisplay">{selectedLanguageStrings.numbers}</div>
                                                                )}
                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-7 col-sm-7 col-md-6 col-lg-4">
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(
                                                        <p className="text-left" style={{ width: 300 }}>
                                                            Automatic jump to poll screen when a question of a poll is pushed "live".
                                                        </p>
                                                    )}
                                                >
                                                    <label className="d-block" id="editTitleFont">
                                                        {selectedLanguageStrings.automatic_jump_poll}
                                                    </label>
                                                </Tooltip>
                                            </div>
                                            <div className="col-2">
                                                {(this.state.automaticJumpPoll === 'default') ? (
                                                    <button type="button" className="text-center tweetsToShow "
                                                        id="removeBtnShadow" onClick={() => {
                                                            this.getUserPreferences('automaticJumpPoll')
                                                        }}>
                                                        <div className={"switch " + addedClassAutomaticJumpPoll}>
                                                            {(this.props.event.automaticJumpPoll === "Active") ? (
                                                                <div
                                                                    className="textToggleOrder text-white ">{selectedLanguageStrings.active}</div>
                                                            ) : (
                                                                <div
                                                                    className="textToggleOrder text-white ">{selectedLanguageStrings.disable}</div>
                                                            )}

                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button type="button" className="text-center tweetsToShow "
                                                        id="removeBtnShadow" onClick={() => {
                                                            this.getUserPreferences('automaticJumpPoll')
                                                        }}>
                                                        <div
                                                            className={"switch " + this.state.addedClassAutomaticJumpPoll}>
                                                            {(this.state.automaticJumpPoll === "Active") ? (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.active}</div>
                                                            ) : (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.disable}</div>
                                                            )}
                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade p-3" id="nav-tweets">
                                        <div className="row mb-2">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-4">
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(
                                                        <p className="text-left" style={{width: 250}}>You are able to
                                                            choose to show the tweets to the participants through the
                                                            participant application or not.
                                                            </p>
                                                    )}
                                                >
                                                    <label className="d-block" id="editTitleFont">
                                                        {selectedLanguageStrings.show_tweets_participants}: 
                                                    </label>
                                                </Tooltip>
                                            </div>
                                            <div className="col-2">
                                                {(this.state.showTweetsToParticipants === 'default') ? (
                                                    <button type='button' className="text-center mt-1 tweetsToShow"
                                                            id="removeBtnShadow" checked onClick={() => {
                                                        this.getUserPreferences('showTweetsToParticipants')
                                                    }}>
                                                        <div className={"switch " + addedClassshowTweetsToParticipants} >
                                                            {(this.props.event.showTweetsToParticipants === "No") ? (
                                                                <div
                                                                    className="textToggleOrder text-white ">{selectedLanguageStrings.no}</div>
                                                            ) : (
                                                                <div
                                                                    className="textToggleOrder text-white ">{selectedLanguageStrings.yes}</div>
                                                                )}
                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button type='button' className="text-center mt-1 tweetsToShow"
                                                            id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('showTweetsToParticipants')
                                                    }}>
                                                        <div
                                                            className={"switch " + this.state.addedClassshowTweetsToParticipants}>
                                                                {(this.state.showTweetsToParticipants === "No") ? (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.no}</div>
                                                                ) : (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.yes}</div>
                                                                    )}
                                                                <div className="inputOrder bg-white"></div>
                                                            </div>
                                                        </button>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-4">
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(
                                                        <p className="text-left" style={{width: 250}}>You are able to
                                                            directly send the received tweets from the participants to
                                                            the big screen and the participant application without need
                                                            to approve them in advance.
                                                            </p>
                                                    )}
                                                >
                                                    <label className="d-block"
                                                           id="editTitleFont">{selectedLanguageStrings.moderation}: </label>
                                                </Tooltip>
                                            </div>
                                            <div className="col-2">
                                                {(this.state.autoApproveTweets === 'default') ? (
                                                    <button type='button' className="text-center mt-1 tweetsToShow"
                                                            id="removeBtnShadow" checked onClick={() => {
                                                        this.getUserPreferences('AutoApproved')
                                                    }}>
                                                        <div className={"switch " + addedClassAutoApproveTweets} >
                                                            {(this.props.event.autoApproveTweets === "With Moderator") ? (
                                                                <div
                                                                    className="textToggleOrder text-white ">{selectedLanguageStrings.yes}</div>
                                                            ) : (
                                                                <div
                                                                    className="textToggleOrder text-white ">{selectedLanguageStrings.no}</div>
                                                                )}
                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button type='button' className="text-center mt-1 tweetsToShow"
                                                            id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('AutoApproved')
                                                    }}>
                                                            <div className={"switch " + this.state.addedClass} >
                                                                {(this.state.autoApproveTweets === "With Moderator") ? (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.yes}</div>
                                                                ) : (
                                                                <div
                                                                    className="textToggleOrder text-white">{selectedLanguageStrings.no}</div>
                                                                    )}
                                                                <div className="inputOrder bg-white"></div>
                                                            </div>
                                                        </button>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-4">
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(
                                                        <p className="text-left" style={{width: 250}}>You can choose
                                                            between the live and approved tweets to show them on the big
                                                            screen and the participant application.

                                                            </p>
                                                    )}
                                                >
                                                    <label className="d-block mb-2"
                                                           id="editTitleFont">{selectedLanguageStrings.define_tweets_show_participants}</label>
                                                </Tooltip>
                                            </div>
                                            {(this.state.autoApproveTweets === 'Without Moderator' || (this.state.autoApproveTweets === 'default' && this.props.event.autoApproveTweets === 'Without Moderator')) ? (
                                                <div className="col-2">
                                                    <button type='button' className="text-center btn tweetsToShow "
                                                            id="removeBtnShadow" disabled>
                                                        <div className={"switch " + this.state.addedClassTweetsToShow} >
                                                            {(this.state.tweetsToShow === "default") ? (
                                                                (this.props.event.tweetsToShow === "Approved") ? (
                                                                    <div className="textToggleOrder text-white">
                                                                        {selectedLanguageStrings.approved}
                                                                    </div>
                                                                ) : (
                                                                    <div className="textToggleOrder text-white">
                                                                        {selectedLanguageStrings.live}
                                                                    </div>
                                                                )
                                                            ) : (
                                                                (this.state.tweetsToShow === "Approved") ? (
                                                                    <div
                                                                        className="textToggleOrder text-white">{selectedLanguageStrings.approved}</div>
                                                                ) : (
                                                                    <div
                                                                        className="textToggleOrder text-white">{selectedLanguageStrings.live}</div>
                                                                )
                                                            )}
                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="col-2">
                                                    {(this.state.tweetsToShow === 'default') ? (
                                                        <button type="button" className="text-center tweetsToShow "
                                                                id="removeBtnShadow" onClick={() => {
                                                            this.getUserPreferences('TweetsToShow')
                                                        }}>
                                                            <div className={"switch " + addedClassTweetsToShow} >
                                                                {(this.props.event.tweetsToShow === "Approved") ? (
                                                                    <div
                                                                        className="textToggleOrder text-white">{selectedLanguageStrings.approved}</div>
                                                                ) : (
                                                                    <div
                                                                        className="textToggleOrder text-white">{selectedLanguageStrings.live}</div>
                                                                    )}
                                                                <div className="inputOrder bg-white"></div>
                                                            </div>
                                                        </button>
                                                    ) : (
                                                        <button type="button" className="text-center tweetsToShow "
                                                                id="removeBtnShadow" onClick={() => {
                                                            this.getUserPreferences('TweetsToShow')
                                                        }}>
                                                            <div
                                                                className={"switch " + this.state.addedClassTweetsToShow}>
                                                                {(this.state.tweetsToShow === "Approved") ? (
                                                                    <div
                                                                        className="textToggleOrder text-white">{selectedLanguageStrings.approved}</div>
                                                                ) : (
                                                                    <div
                                                                        className="textToggleOrder text-white">{selectedLanguageStrings.live}</div>
                                                                    )}
                                                                <div className="inputOrder bg-white"></div>
                                                            </div>
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="tab-pane fade p-3" id="nav-participants">
                                        <div className="row mb-2">
                                            <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(
                                                        <p className="text-left" style={{ width: 250 }}>
                                                            Activate profile info on clients.
                                                        </p>
                                                    )}
                                                >
                                                    <label className="d-block" id="editTitleFont">
                                                        {selectedLanguageStrings.active_profile}
                                                    </label>
                                                </Tooltip>
                                            </div>
                                            <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                                                {(this.state.showProfileToParticipants === 'default') ? (
                                                    <button type='button' className="text-center mt-1 tweetsToShow"
                                                            id="removeBtnShadow" checked onClick={() => {
                                                        this.getUserPreferences('showProfileToParticipants')
                                                    }}>
                                                        <div
                                                            className={"switch " + addedClassshowProfileToParticipants}>
                                                            {(this.props.event.showProfileToParticipants === "Yes") ? (
                                                                <div className="textToggleOrder text-white ">
                                                                    {selectedLanguageStrings.yes}
                                                                </div>
                                                            ) : (
                                                                <div className="textToggleOrder text-white ">
                                                                    {selectedLanguageStrings.no}
                                                                </div>
                                                            )}
                                                                <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button type='button' className="text-center mt-1 tweetsToShow"
                                                            id="removeBtnShadow" checked onClick={() => {
                                                        this.getUserPreferences('showProfileToParticipants')
                                                    }}>
                                                        <div
                                                            className={"switch " + this.state.addedClassshowProfileToParticipants}>
                                                            {(this.state.showProfileToParticipants === "Yes") ? (
                                                                <div className="textToggleOrder text-white">
                                                                    {selectedLanguageStrings.yes}
                                                                </div>
                                                            ) : (
                                                                <div className="textToggleOrder text-white">
                                                                    {selectedLanguageStrings.no}
                                                                </div>
                                                            )}
                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                )}
                                            </div>
                                        </div>   

                                        <div className="row mb-2">
                                            <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                                                <Tooltip
                                                    // options
                                                    position="top"
                                                    trigger={"mouseenter"}
                                                    distance="5"
                                                    animation={"fade"}
                                                    arrow={true}
                                                    duration={1200}
                                                    html={(
                                                        <p className="text-left" style={{ width: 250 }}>
                                                            Activate add email on clients profile.
                                                        </p>
                                                    )}
                                                >
                                                    <label className="d-block" id="editTitleFont">
                                                        {selectedLanguageStrings.active_email}
                                                    </label>
                                                </Tooltip>
                                            </div>
                                            <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                                                {(this.state.showEmailToParticipants === 'default') ? (
                                                    <button type='button' className="text-center mt-1 tweetsToShow"
                                                            id="removeBtnShadow" checked onClick={() => {
                                                        this.getUserPreferences('showEmailToParticipants')
                                                    }}>
                                                        <div className={"switch " + addedClassShowEmailToParticipants} >
                                                            {(this.props.event.showEmailToParticipants === "Yes") ? (
                                                                <div className="textToggleOrder text-white ">
                                                                    {selectedLanguageStrings.yes}
                                                                </div>
                                                            ) : (
                                                                <div className="textToggleOrder text-white ">
                                                                    {selectedLanguageStrings.no}
                                                                </div>
                                                            )}
                                                                <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                ):(
                                                    <button type='button' className="text-center mt-1 tweetsToShow"
                                                            id="removeBtnShadow" onClick={() => {
                                                        this.getUserPreferences('showEmailToParticipants')
                                                    }}>
                                                        <div
                                                            className={"switch " + this.state.addedClassShowEmailToParticipants}>
                                                            {(this.state.showEmailToParticipants === "Yes") ? (
                                                                <div className="textToggleOrder text-white">
                                                                    {selectedLanguageStrings.yes}
                                                                </div>
                                                            ) : (
                                                                <div className="textToggleOrder text-white">
                                                                    {selectedLanguageStrings.no}
                                                                </div>
                                                            )}
                                                            <div className="inputOrder bg-white"></div>
                                                        </div>
                                                    </button>
                                                )}
                                            </div>
                                        </div>   
                        
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {(this.props.event.id === 0) ? (
                                        <div>
                                        <button type="button" className="btn btn-secondary" id="hidePopUpBtn"
                                                data-dismiss="modal"
                                                onClick={this.closeFunction}>{selectedLanguageStrings.cancel}</button>
                                        <input type="submit" className="btn btn-success ml-2" id="saveButton"
                                               onClick={this.checkInputStatuses.bind(this, true)}
                                               value={selectedLanguageStrings.create}/>
                                        </div>
                                    ) : (
                                        <div>
                                        <button type="button" className="btn btn-secondary" id="hidePopUpBtn"
                                                data-dismiss="modal"
                                                onClick={this.closeFunction}>{selectedLanguageStrings.cancel}</button>
                                        <button type="submit" className="btn btn-primary" id="saveButton"
                                                onClick={this.checkInputStatuses.bind(this, false)}>{selectedLanguageStrings.update}</button>
                                        <button type="submit" className="btn btn-success ml-2" id="saveButton"
                                                onClick={this.checkInputStatuses.bind(this, true)}>{selectedLanguageStrings.ok}</button>
                                        </div>

                                )}
                            </div>
                        </div>
                    </div >
                </div >
            </form>
        );
    }
}
// export default EditModal;
const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {

        checkEventCode: (event, eventCode, callback) => dispatch(eventActions.checkCodeAvailability(event, eventCode, callback)),
        onSaveEvent: (userId, event, callback) => dispatch(eventActions.saveEvent(userId, event, callback)),
        onUploadEventLogo: (userId, eventId, type, imageFile, callback) => dispatch(eventActions.uploadEventLogo(userId, eventId, type, imageFile, callback)),
        onUpdateCurrentField: (option, eventId, value, callback) => dispatch(eventActions.updateCurrentField(option, eventId, value, callback)),


    };
};


export default connect(mapStateToProps, mapDispatchToProps)(EditModal);